import Vue from "vue"
import VueRouter from "vue-router";
import HomeCmp from "./views/Home";
import Login from "./views/Login";
import Account from "./views/Account";
import Register from "./views/Register";
import Product from "@/views/Products/Products";
import NewProduct from "@/views/Products/NewProduct";
import Statistics from "@/views/Statistics/Statistics";
import Order from "@/views/Orders/Order";
import EditProduct from "@/views/Products/EditProduct";
import Pages from "@/views/Pages/Pages";
import EditPage from "@/views/Pages/EditPage"
import SingleOrder from "@/views/Orders/SingleOrder";
import Users from "@/views/Users/Users";
import SingleUser from "@/views/Users/SingleUser";
import Settings from "@/views/Settings/Settings";

import RawMaterials from "@/views/Raw-Materials/RawMaterials";
import RawMaterialSlug from "@/views/Raw-Materials/RawMaterialSlug";
import Plans from "@/views/Plans/Plans";
import EditPlan from "@/views/Plans/EditPlan";
import NewPlan from "@/views/Plans/NewPlan";

Vue.use(VueRouter);

const routes = [
    {path: '/',name: 'home', component: HomeCmp,
        meta: {requiresAuth: true}
    },
    {path: '/login', name: 'login', component: Login,
        meta:{ authPage: true }
    },
    {path: '/register', name: 'register', component: Register,
        meta:{ authPage: true }
    },
    {path: '/account', name: 'account', component: Account},
    {path: '/products', name: 'products', component: Product},
    {path: '/products/single/:itemId', name: 'edit-product', component: EditProduct},
    {path: '/orders', name: 'orders', component: Order},
    {path: '/orders/:orderId', name: 'orders-id', component: SingleOrder},
    {path: '/plans', name: 'plans', component: Plans},
    {path: '/plans/single/:itemId', name: 'edit-plan', component: EditPlan},
    {path: '/plans/create', name: 'plans-create', component: NewPlan},
    {path: '/users', name: 'users', component: Users},
    {path: '/users/:itemId', name: 'users-slug', component: SingleUser},
    {path: '/pages', name: 'pages', component: Pages},
    {path: '/pages/:slug', name: 'pages-slug', component: EditPage},
    {path: '/statistics', name: 'statistics', component: Statistics},
    {path: '/settings', name: 'settings', component: Settings},
    {path: '/products/create', name: 'products-create', component: NewProduct},

    {path: '/raw-materials', name: 'raw-materials', component: RawMaterials},
    {path: '/raw-materials/:itemId', name: 'raw-materials-slug', component: RawMaterialSlug}

];

const router = new VueRouter({
    // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
    mode: 'history',
    routes, // short for `routes: routes`
});

router.beforeEach((to, from, next) => {

    const isLoggedIn = !!localStorage.getItem('isLoggedIn');

    if(to.meta.authPage && isLoggedIn){
        next({name: 'account'});
        return;
    }

    // for non logged in pages, we just go to login
    if(!to.meta.authPage && !isLoggedIn){
        next({name: 'login'});
        return;
    }

    next();
});

export default router;