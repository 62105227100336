<template>

  <div id="pages">

    <div class="container">
      <div class="row align-items-center">
        <div class="col-auto">
          <h2>Pages</h2>
        </div>
        <div class="col-auto ml-auto ext-right">
          <b-button class="ml-auto" size="md" variant="primary" @click="newPage">Add new</b-button>
        </div>
      </div>
    </div>


    <div class="container mt-5">
      <div class="row">
        <div v-for="page in pages" :key="page.id" class="col-12 shadow-sm my-2 d-flex align-items-center rounded py-2" >
          <div>
            <h5 class="font-weight-bold cursor-pointer" @click="redirectTo('pages-slug', {slug:page.slug})">{{page.title}}</h5>
            <p class="text-muted">link: <a :href="`https://www.dreamdiet.shop/pages/${page.slug}`" target="_blank">https://www.dreamdiet.shop/pages/{{page.slug}} </a></p>

          </div>

          <div class="ml-auto">
            <b-button variant="warning" @click="redirectTo('pages-slug', {slug:page.slug})">Edit</b-button>
          </div>



        </div>

        <div v-if="pages.length === 0" class="col-12 text-center mt-5">
          <span class="feather icon-cloud-off" style="font-size:50px;"/><br>
            No pages found!<br>
        </div>


        <div class="col-12 my-4">
          <b-pagination
              align="right"
              v-model="query.page"
              :total-rows="count"
              :per-page="query.limit"
              aria-controls="my-table"
          ></b-pagination>

        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default{

  data(){
    return{
      pages: [],
      count: 0,
      query:{
        limit: 50,
        page: 1,
        search: '',

      }
    }
  },

  watch:{

    'query.page'(){
      this.getPages();
    },

    'query.search'(){
      this.query.page = 1;
      this.getPages()
    }

  },

  mounted(){

    this.getPages()
    if(this.$route.query.page) {
      this.query.page = this.$route.query.page
    }
  },

  methods:{
    getPages(){

      let query = {...this.query};

      query.offset = (query.page-1) * query.limit

      this.axios.get('/pages', {params: query}).then(res=>{
        this.pages = res.data.rows;
        this.count = res.data.count;
      }).catch(()=>{
        this.$notify({type: 'error', title:'Something went wrong', text: 'Please try again later!'});
      })
    },
    newPage(){
      this.axios.post('/pages').then(res=>{
        this.redirectTo('pages-slug', res.data.slug);
      }).catch(()=>{
        this.$notify({type: 'error', title:'Something went wrong', text: 'Please try again later!'});
      })
    }
  }

}
</script>