
<template>

  <div v-if="user" id="single-user">

    <div class="container-large">
      <div class="row align-items-center">
        <div class="col-auto">
          <h2> {{user.firstName}} {{user.lastName}}</h2>
          <h5>{{user.email}}</h5>
        </div>
        <div class="col-auto ml-auto ext-right">

          <b-button variant="success" @click="updateUser" :disabled="!userChanged">Αποθήκευση αλλαγών</b-button>
        </div>
      </div>
    </div>

    <div class="container-large mt-4">


      <b-tabs v-model="activeTab" content-class="mt-4" @change="()=>{
              if(this.activeTab === 3) this.activeTab=  2}">

        <b-tab title="Γενικές πληροφορίες">
          <div class="row">

            <div class="col-12 col-md-6">

              <div class="details mb-4">
                <div class="section-toggle mb-3 d-flex align-items-center">
                  <h6 class="font-weight-bold mb-0 " >Πληροφορίες πελάτη</h6>
                </div>


                <div>
                  <table  class="w-100 b mt-3">
                    <tbody>
                    <tr v-for="field in fields" :key="field.key" class="user-detail-item">
                      <td class="py-1 font-weight-light title-label" style="width:35%">{{ field.title}}</td>
                      <td class="  font-weight-bold pr-5 title" style="width:65%">

                    <span>
                 {{ user[field.key] || '-'}}
                  </span></td>
                    </tr>
                    </tbody>
                  </table>
                </div>




              </div>
            </div>
          </div>
        </b-tab>

        <b-tab title="Οδηγός Διατροφής">

          <wysiwyg v-model="user.guideContent" class="w-100"></wysiwyg>
        </b-tab>

        <b-tab title="Ιστορικό μετρήσεων">

          <div>
            Σύντομα κοντά σας.
          </div>
        </b-tab>

        <b-tab @click.prevent="viewOrders">

          <template #title>
            Παραγγελίες χρήστη
            <span class="icon-external-link"></span>
          </template>
          <div>


            <b-button variant="primary" size="sm" @click="viewOrders">Πατήστε εδώ για να δείτε τις παραγγελίες του χρήστη</b-button>
          </div>
        </b-tab>
      </b-tabs>


    </div>


  </div>
</template>
<style lang="scss">
.stock-notice{
  list-style-type:circle;
  padding-left:20px;
}
.user-detail-item{
  &:nth-child(even){
    background-color:$primary_light;
  }
  .title-label{
    padding-left:0.5rem;
    font-size:13px;
  }
  .title{
    font-size:14px;
  }
}
.user-item-wrap{
  buser:1px solid $primary_shade;
  margin-top:-1px;

  .title-label{
    font-size:9px;
  }
  .title{
    font-size:14px;
  }
  &:first-child{
    buser-top-left-radius: 12px!important;
    buser-top-right-radius: 12px!important;
  }
  &:last-child{
    buser-bottom-left-radius: 12px;
    buser-bottom-right-radius: 12px;
  }
  &:nth-child(even){
    background-color:$primary_light;
  }
}
.section-toggle{
  cursor:pointer;
}
</style>

<script>

import {mapState} from "vuex";

export default{

  computed:{
    ...mapState({
      statuses: state => state.statuses,
      status_colors: state => state.status_colors
    }),

    userChanged(){

      return this.loaded && JSON.stringify(this.user) !== this.initialUser
    }
  },

  data(){
    return{
      user: null,
      loaded: false,
      fields: [{title: 'User ID', key: 'id'},
        {title: 'Όνομα', key: 'firstName'}, {title: 'Επώνυμο', key: 'lastName'}, {title: 'E-mail', key: 'email'},
        {title: 'Ύψος', key: 'height'},
        {title: 'Hμ. Γέννησης', key: 'birthDate'},
      ],
      orderFields:  [
          {title: 'Order Id', key: 'id'},
      {title: 'Status', key: 'status'},
      {title: 'Total', key: 'total'},
        {title: 'Discount', key: 'discount'}],

      userOrders: [],
      initialUser: {},
      activeTab: 0
    }
  },
  mounted(){
    this.getUser();

  },
  methods:{


    viewOrders(){
      window.open('/orders?email='+ this.user.email, '_blank');
    },
    getUser(){
      this.axios.get('/admin/users/'+this.$route.params.itemId).then(res=>{
        this.user = res.data;
        this.initialUser = JSON.stringify(res.data);
        this.getUsersOrders();
      }).catch(()=>{
        this.$notify({type: 'error', title:'Something went wrong', text: 'Please try again later!'});
      })
    },

    getUsersOrders(){
      this.axios.get('/admin/orders', {params: {search: this.user.email, limit: 1000}}).then(res=>{
        this.userOrders = res.data.rows;
        this.loaded = true;
      }).catch(()=>{
        this.$notify({type: 'error', title:'Something went wrong', text: 'Please try again later!'});
      })
    },

    updateUser(){
      this.axios.put('/admin/users/'+this.$route.params.itemId, this.user).then(res=>{
        this.user = res.data;
        this.initialUser = JSON.stringify(res.data);
        this.$notify({type: 'success', title:'Επιτυχία!', text: 'O χρήστης ενημερώθηκε επιτυχώς.'});
      }).catch(()=>{
        this.$notify({type: 'error', title:'Something went wrong', text: 'Please try again later!'});
      })
    },
    openOrder(orderId){
      window.open('/orders/'+orderId, '_blank')
    },

  }
}
</script>