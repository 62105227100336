<template>

  <div id="materials">

    <div class="container-large">
      <div class="row align-items-center">
        <div class="col-auto">
          <h2>Πρώτες ύλες</h2>
        </div>
        <div class="col-auto ml-auto ext-right">

          <b-button variant="primary" @click="openCreateUpdateNewMaterialModal">Δημιουργία νέας</b-button>
        </div>
      </div>
    </div>

    <div class="container-large my-3">

      <div class="row">

        <div class="col-12 col-md-4">
          <b-form-input v-model="query.search" debounce="500" placeholder="Αναζήτηση (όνομα, barcode)" class="py-2"></b-form-input>
        </div>
      </div>
    </div>

    <div class="container-large  mt-4">
      <div class="row striped-data">

        <div class="col-12">
          <vue-good-table
              v-if="loaded"
              ref="orders-table"
              mode="remote"
              :pagination-options="{
                enabled: true,
                total: totalCount,
                setCurrentPage: this.query.page,
                dropdownAllowAll: false,
                perPage: this.query.limit,
                rowsPerPageLabel: 'Ανά σελίδα',
                nextLabel:'Επόμενη',
                prevLabel: 'Προηγούμενη',
                perPageDropdown: [10, 15, 20, 25],
              }"
              :totalRows="totalCount"
              :sort-options="{ enabled: false }"
              style-class="vgt-table striped condensed"
              theme="polar-bear"
              @on-page-change="params => this.query.page = params.currentPage"
              @on-row-click="params => this.$router.push({name: 'raw-materials-slug', params: {itemId: params.row.id}})"
              @on-per-page-change="params => this.query.limit = params.currentPerPage"
              :columns="columns"
              :rows="data"
              :select-options="{
                  enabled: false,
                  selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
                  selectionInfoClass: 'custom-class',
                  selectionText: 'rows selected',
                  clearSelectionText: 'clear',
                  disableSelectInfo: true, // disable the select info panel on top
                  selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
                }">
            <!--          <template slot="table-row" slot-scope="props">-->
            <!--          </template>-->

            <div slot="emptystate">
              Δεν βρέθηκαν πρώτες ύλες
            </div>
          </vue-good-table>
        </div>


      </div>
    </div>

    <b-modal v-if="newRawMaterial" no-close-on-backdrop ref="update-create-raw-material"
             :ok-title="newRawMaterial.id ? 'Επεξεργασία' : 'Δημιουργία'"
             cancel-title="Ακύρωση"
             class="form-modal" :title="(newRawMaterial.id ? 'Επεξεργασία' : 'Δημιουργία') + ' πρώτης ύλης'"
    @ok.prevent="createOrUpdateMaterial">

      <div class="row">
        <div class="col-12">
          <label>Όνομα πρώτης ύλης</label>
          <b-form-input v-model="newRawMaterial.name" placeholder="π.χ. Πατάτες"></b-form-input>
        </div>
      </div>
    </b-modal>
  </div>

</template>
<style lang="scss">
.order-item{
  cursor:pointer;
  margin-top:2px;
  &:nth-child(even){
    background-color:$primary_shade;
  }
  border-color:$primary_shade;
  &:hover{
    background-color:$primary_shade;
  }
  > div{
    font-size:13px;
    font-weight:500;
  }
}
</style>
<script>

export default{

  data(){
    return{
      data: [],
      totalCount: 0,
      query:{
        limit: 10,
        page: 1,
        search: '',
        include_available_stock: true
      },
      loaded: false,
      newRawMaterial: {
        name: null
      },
      columns: [
        {label: '#Id', field: 'id'},
        {label: 'Όνομα', field: 'name'},
        {label: 'Διαθέσιμη ποσότητα', field: 'availableAmount'},
        {label: 'Ημερομηνία δημιουργίας', field: 'createdAt', type: 'date', dateInputFormat: 'yyyy-MM-dd', dateOutputFormat: 'dd-MM-yyyy'},
      ]
    }
  },

  watch:{

    'query.limit'(){
      this.getData();
    },

    'query.page'(val){
      if(!this.loaded) return;
      this.fixUrlQuery('page', val);
      this.getData();
    },

    'query.search'(val){
      if(!this.loaded) return;
      this.query.page = 1;
      this.fixUrlQuery('search', val);
      this.getData()
    }

  },

  mounted(){


    if(this.$route.query.page) this.query.page = this.$route.query.page
    if(this.$route.query.search) this.query.search = this.$route.query.search;

    this.getData()
  },

  methods:{


    getData(){

      let query = {...this.query};

      query.offset = (query.page-1) * query.limit

      this.axios.get('/admin/materials', {params: query}).then(res=>{
        this.data = res.data.rows;
        this.totalCount = res.data.count;
        this.loaded = true;
      }).catch(()=>{
        this.$notify({type: 'error', title:'Something went wrong', text: 'Please try again later!'});
      })
    },

    openCreateUpdateNewMaterialModal(){
      this.$refs['update-create-raw-material'].show();
    },

    createOrUpdateMaterial(){

      this.axios.post('/admin/materials', {name: this.newRawMaterial.name}).then(()=>{

        this.$notify({type: 'success', title:'Επιτυχία', text: 'Η δημιουργία ολοκληρώθηκε επιτυχώς.'});
        this.$refs['update-create-raw-material'].hide();
        this.newRawMaterial.name = null;
        this.getData();

      }).catch(()=>{
        this.$notify({type: 'error', title:'Something went wrong', text: 'Please try again later!'});
      }).finally(()=>{
      });
    }
  }

}
</script>