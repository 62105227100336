var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"users"}},[_vm._m(0),_c('div',{staticClass:"container-large my-3"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-4"},[_c('b-form-input',{staticClass:"py-2",attrs:{"debounce":"500","placeholder":"Αναζήτηση πελάτη..."},model:{value:(_vm.query.search),callback:function ($$v) {_vm.$set(_vm.query, "search", $$v)},expression:"query.search"}})],1)])]),_c('div',{staticClass:"container-large mt-4"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[(_vm.loaded)?_c('vue-good-table',{ref:"orders-table",attrs:{"mode":"remote","pagination-options":{
              enabled: true,
              total: _vm.totalUsers,
              setCurrentPage: this.query.page,
              dropdownAllowAll: false,
              perPage: this.query.limit,
              rowsPerPageLabel: 'Ανά σελίδα',
              nextLabel:'Επόμενη',
              prevLabel: 'Προηγούμενη',
              perPageDropdown: [10, 15, 20, 25],
            },"totalRows":_vm.totalUsers,"sort-options":{ enabled: false },"style-class":"vgt-table striped condensed","theme":"polar-bear","columns":_vm.columns,"rows":_vm.users,"select-options":{
                enabled: true,
                selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
                selectionInfoClass: 'custom-class',
                selectionText: 'rows selected',
                clearSelectionText: 'clear',
                disableSelectInfo: true, // disable the select info panel on top
                selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
              }},on:{"on-page-change":params => this.query.page = params.currentPage,"on-row-click":_vm.viewUser,"on-per-page-change":params => this.query.limit = params.currentPerPage}},[_c('div',{attrs:{"slot":"emptystate"},slot:"emptystate"},[_vm._v(" Δεν βρέθηκαν δεδομένα ")])]):_vm._e()],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-large"},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-auto"},[_c('h2',[_vm._v("Πελάτες / Διαχειριστές")])]),_c('div',{staticClass:"col-auto ml-auto text-right"})])])
}]

export { render, staticRenderFns }