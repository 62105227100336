
<template>
  <div class="p-3">


    <b-form-checkbox v-model="notification.show" id="show-notification" name="show-notification" class="flex align-items-center">
      Enable global site notification
    </b-form-checkbox>

    <div v-if="notification.show" class="mt-3">
      <label>Global notification text</label>
      <b-form-input v-if="notification.show" v-model="notification.text" placeholder="Global notification text"/>
    </div>

    <div class="mt-4 text-right">
      <b-button variant="success" @click="saveChanges" :disabled="!hasChanged">Save Changes</b-button>
    </div>

  </div>

</template>

<script>
export default{

  computed:{
    hasChanged(){

      return false;
    }
  },

  mounted(){
    this.getCycleInformation();
  },
  methods:{
    getCycleInformation(){

      this.axios.get('/admin/settings/day_of_delivery,day_of_delivery_closing,active_cycle,active_cycle_start_date,active_cycle_end_date').then(res=>{
        console.log(res);
      }).catch(()=>{

      });

    },
    saveChanges(){}
  },
  data(){
    return{
    }
  },


}
</script>