<template>

  <div id="edit-product">

    <div v-if="plan" class="p-4">
      <plan-details :plan="plan" @refreshPlan="fetchPlan"/>
    </div>


  </div>
</template>


<script>
import PlanDetails from "@/components/PlanDetails";
export default{

  components: {PlanDetails},

  mounted(){
    this.fetchPlan();
  },

  methods:{
    fetchPlan(){
      this.axios.get('/admin/plans/'+ this.$route.params.itemId).then(res=>{
        this.plan = res.data;
      }).catch(()=>{
        this.$notify({type: 'error', title:'Something went wrong', text: 'Please try again later!'});
      })
    }
  },
  data(){
    return{
      plan: null
    }
  }

}
</script>