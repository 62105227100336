
<template>

  <div v-if="order" id="single-order">

    <div class="container-large">
      <div class="row align-items-center">
        <div class="col-auto">
          <h2>Παραγγελία #{{order.id}}</h2>
        </div>
        <div class="col-auto ml-auto ext-right">
          <b-dropdown size="sm" variant="primary" class="mr-2" text="Ενέργειες" right>
            <b-dropdown-item v-for="item in statuses.filter(x => x.status !== order.status && !!x.status)" :key="item.status"
                             size="xs"
                             class="text-sm"
                             @click="updateStatus(item.status)">
              Αλλαγή κατάστασης παραγγελίας σε <span class="font-weight-bold" :style="`color:${status_colors[item.status]}`">{{item.title}}</span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
    </div>

    <div class="container-large mt-4">

      <div class="row">

        <div class="col-12 col-md-6">

          <div class="details mb-4">
            <div class="section-toggle mb-3 d-flex align-items-center" @click="toggle.details = !toggle.details">
              <h6 class="font-weight-bold mb-0 " >Σύνοψη παραγγελίας</h6>
              <span class="ml-auto" :class="toggle.details ? 'icon-chevron-down' : 'icon-chevron-right'"></span>
            </div>


            <div v-show="toggle.details">
              <table  class="w-100 b mt-3">
                <tbody>
                <tr v-for="field in fields" :key="field.key" class="order-detail-item">
                  <td class="py-1 font-weight-light title-label" style="width:35%">{{ field.title}}</td>
                  <td class="  font-weight-bold pr-5 title" style="width:65%">

                  <span v-if="field.key === 'status'" :style="`font-weight:bold;color:${status_colors[order.status]};`">

                    {{$store.getters.getStatus(order[field.key])}}
                  </span>
                    <span v-else-if="field.key === 'delivery_date'" >

                    {{formatDate(new Date(order[field.key]))}}
                  </span>
                    <span v-else-if="field.key === 'type'" >

                    {{$store.state.types[order.order_setting.type] }}
                  </span>
                    <span v-else>
                 {{ order[field.key] || '-'}}
                  </span></td>
                </tr>
                </tbody>
              </table>
            </div>


          </div>

          <div class="products">
            <div class="section-toggle mb-3 d-flex align-items-center" @click="toggle.products = !toggle.products">
              <h6 class="font-weight-bold mb-0">Προϊοντα παραγγελίας</h6>
              <span class="ml-auto" :class="toggle.products ? 'icon-chevron-down' : 'icon-chevron-right'"></span>
            </div>

            <div v-show="toggle.products" class="wrapper px-3" style="max-height:400px;overflow-y:auto;">

              <b-tabs content-class="mt-3">
                <b-tab v-for="(category) in getActiveCategories" :key="category" :title="`${categories[category]} (${getProductsByCategory(category).length}) `" >
                  <div v-for="item in getProductsByCategory(category)" :key="item.id" class="row order-item-wrap align-items-center py-2 ">

                    <div class="col-12 col-md-2">
                      <image-preview width="60" height="60" :style="`border-radius:99px;`" :url="item.image"/>
                    </div>

                    <div class="col-6 col-md-5">
<!--                      <p class="sub font-weight-bold text-xs mb-0 block bg-primary text-white py-1 px-1 rounded-lg " style="width:fit-content">-->
<!--                        {{categories[item.category]}}-->
<!--                      </p>-->
                      <span class="font-weight-bold title">{{item.name}} - <span class="text-capitalize">{{item.size}}</span></span>

                    </div>
                    <div class="col-6 col-md-2">
                      <span class="font-weight-light title-label">Ποσότητα</span><br>
                      <span class="font-weight-bold title">{{item.quantity}}</span>
                    </div>
                    <div class="col-6 col-md-2">
                      <span class="font-weight-light title-label">Τιμή (/τεμάχιο)</span><br>
                      <span class="font-weight-bold title">{{item.price ? item.price.toFixed(2) : item.price}}€</span>
                    </div>

                    <div class="px-4">

                    </div>

                  </div>
                </b-tab>
              </b-tabs>
<!--              <div v-for="item in order.order_products" :key="item.id" class="row order-item-wrap align-items-center py-2 ">-->

<!--                <div class="col-12 col-md-2">-->
<!--                  <image-preview width="60" height="60" :style="`border-radius:99px;`" :url="item.image"/>-->
<!--                </div>-->

<!--                <div class="col-6 col-md-5">-->
<!--                  <p class="sub font-weight-bold text-xs mb-0 block bg-primary text-white py-1 px-1 rounded-lg " style="width:fit-content">-->
<!--                    {{categories[item.category]}}-->
<!--                    {{item.category}}-->
<!--                  </p>-->
<!--                  <span class="font-weight-bold title">{{item.name}} - <span class="text-capitalize">{{item.size}}</span></span>-->

<!--                </div>-->
<!--                <div class="col-6 col-md-2">-->
<!--                  <span class="font-weight-light title-label">Ποσότητα</span><br>-->
<!--                  <span class="font-weight-bold title">{{item.quantity}}</span>-->
<!--                </div>-->
<!--                <div class="col-6 col-md-2">-->
<!--                  <span class="font-weight-light title-label">Τιμή (/τεμάχιο)</span><br>-->
<!--                  <span class="font-weight-bold title">{{item.price ? item.price.toFixed(2) : item.price}}€</span>-->
<!--                </div>-->

<!--                <div class="px-4">-->

<!--                </div>-->

<!--              </div>-->

            </div>

          </div>


        </div>
        <div class="col-12 col-md-6">
          <div class="details mb-4 px-3">
            <div class="section-toggle mb-3 d-flex align-items-center" @click="toggle.shipping = !toggle.shipping">
              <h6 class="font-weight-bold mb-0 " >Διεύθυνση αποστολής</h6>
              <span class="ml-auto" :class="toggle.shipping ? 'icon-chevron-down' : 'icon-chevron-right'"></span>
            </div>


            <div v-show="toggle.shipping">
              <div class="text-right">
                <b-button size="xs" :variant="edit.shipping ? 'link' :'warning'" class="ml-auto" @click="edit.shipping = !edit.shipping">
                  {{edit.shipping ? 'Άκυρο': 'Επεξεργασία'}}
                </b-button>

              </div>
              <table class="w-100 b mt-3">
                <tbody>
                <tr v-for="field in shipping_fields" :key="field.key" class="order-detail-item">
                  <td class="py-1 font-weight-light title-label" style="width:35%">{{ field.title}}</td>
                  <td class="font-weight-bold pr-3 title" style="width:65%">

                    <span v-if="!edit.shipping">{{ order.order_shipping_address[field.key] || '-'}}</span>
                    <b-form-input v-else type="text" class="my-2" v-model="order.order_shipping_address[field.key]"></b-form-input></td>
                </tr>
                </tbody>
              </table>
            </div>



          </div>

          <div class="notes mb-4 px-3">
            <div class="section-toggle mb-3 d-flex align-items-center" @click="toggle.notes = !toggle.notes">
              <h6 class="font-weight-bold mb-0 " >Σημειώσεις συστήματος</h6>
              <span class="ml-auto" :class="toggle.notes ? 'icon-chevron-down' : 'icon-chevron-right'"></span>
            </div>


            <div v-show="toggle.notes">
              <table class="w-100 b mt-3">
                <tbody>
                <tr v-for="note in order.order_notes" :key="note.id" class="order-detail-item ">
                  <td class="py-1 font-weight-light w-25 title-label">{{formatDateWithTime(new Date(note.createdAt))}}</td>
                  <td class="w-75 pr-3 title py-2"><div v-html="note.note"></div></td>


                </tr>
                </tbody>
              </table>
            </div>



          </div>
        </div>
      </div>


    </div>


  </div>
</template>
<style lang="scss">
.stock-notice{
  list-style-type:circle;
  padding-left:20px;
}
.order-detail-item{
  &:nth-child(even){
    background-color:$primary_light;
  }
  .title-label{
    padding-left:0.5rem;
    font-size:13px;
  }
  .title{
    font-size:14px;
  }
}
.order-item-wrap{
  border:1px solid $primary_shade;
  margin-top:-1px;

  .title-label{
    font-size:9px;
  }
  .title{
    font-size:14px;
  }
  &:first-child{
    border-top-left-radius: 12px!important;
    border-top-right-radius: 12px!important;
  }
  &:last-child{
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }
  &:nth-child(even){
    background-color:$primary_light;
  }
}
.section-toggle{
  cursor:pointer;
}
</style>

<script>

import {mapState} from "vuex";

export default{

  computed:{
    ...mapState({
      statuses: state => state.statuses,
      status_colors: state => state.status_colors
    }),

    getActiveCategories(){

      return Object.keys(this.categories).filter(y =>{
        return this.getProductsByCategory(y).length > 0
      })
    }
  },
  data(){
    return{
      order: null,
      fields: [{title: '# ID', key: 'id'},
        {title: 'Κατάσταση παραγγελίας', key: 'status'},
        {title: 'Ημ. Παράδοσης', key: 'delivery_date'},
        {title: 'Eίδος Παραγγελίας', key: 'type'},
        {title: 'Όνομα', key: 'firstName'}, {title: 'Επώνυμο', key: 'lastName'}, {title: 'E-mail', key: 'email'}, {title: 'Τηλέφωνο', key: 'phone'}],
      shipping_fields: [
        {title: 'Διεύθυνση', key: 'address'},
        // {title: 'Country', key: 'country'},
        {title: 'Πόλη', key: 'city'},
        {title: 'Τ.Κ.', key: 'zipCode'},
        {title: 'Όροφος / όνομα στο κουδούνι', key: 'floor_bell'},
        {title: 'Σημειώσεις', key: 'notes'}
      ],

      categories:{
        BREAKFAST: 'Πρωϊνό',
        LUNCH:'Μεσημεριανό',
        DINNER: 'Βραδινό',
        SNACK: 'Σνακ',
        GRAB_GO_DINNER: 'Grab & Go Βραδινό',
        DESERT: 'Επιδόρπιο'
      },
      toggle:{
        shipping: true,
        details: true,
        products: true,
        actions: false,
        notes: true,
      },
      edit:{
        shipping: false,
        details: false,
        products: false
      }
    }
  },
  mounted(){
    this.getOrder();

  },
  methods:{

    getProductsByCategory(categoryId){
      return this.order.order_products.filter(x => x.category === categoryId);
    },
    getOrder(){
      this.axios.get('/admin/orders/'+this.$route.params.orderId).then(res=>{
        this.order = res.data;
      }).catch(()=>{
        this.$notify({type: 'error', title:'Something went wrong', text: 'Please try again later!'});
      })
    },

    updateStatus(status){
      this.axios.put('/admin/orders/'+this.$route.params.orderId + '/status', {status:status}).then(()=>{
        this.getOrder();
      }).catch((res)=>{
        if(res.response.data && res.response.data.code === 50){
          this.$notify({type: 'error', title:'Cannot update the order because some products are out of stock!',
            text: 'Please make sure you have enough stock to finalize the order.'});
        }else{
          this.$notify({type: 'error', title:'Something went wrong', text: 'Please try again later!'});
        }

      });
    }

  }
}
</script>