var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"materials"}},[_c('div',{staticClass:"container-large"},[_c('div',{staticClass:"row align-items-center"},[_vm._m(0),_c('div',{staticClass:"col-auto ml-auto ext-right"},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.openCreateUpdateNewMaterialModal}},[_vm._v("Δημιουργία νέας")])],1)])]),_c('div',{staticClass:"container-large my-3"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-4"},[_c('b-form-input',{staticClass:"py-2",attrs:{"debounce":"500","placeholder":"Αναζήτηση (όνομα, barcode)"},model:{value:(_vm.query.search),callback:function ($$v) {_vm.$set(_vm.query, "search", $$v)},expression:"query.search"}})],1)])]),_c('div',{staticClass:"container-large mt-4"},[_c('div',{staticClass:"row striped-data"},[_c('div',{staticClass:"col-12"},[(_vm.loaded)?_c('vue-good-table',{ref:"orders-table",attrs:{"mode":"remote","pagination-options":{
              enabled: true,
              total: _vm.totalCount,
              setCurrentPage: this.query.page,
              dropdownAllowAll: false,
              perPage: this.query.limit,
              rowsPerPageLabel: 'Ανά σελίδα',
              nextLabel:'Επόμενη',
              prevLabel: 'Προηγούμενη',
              perPageDropdown: [10, 15, 20, 25],
            },"totalRows":_vm.totalCount,"sort-options":{ enabled: false },"style-class":"vgt-table striped condensed","theme":"polar-bear","columns":_vm.columns,"rows":_vm.data,"select-options":{
                enabled: false,
                selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
                selectionInfoClass: 'custom-class',
                selectionText: 'rows selected',
                clearSelectionText: 'clear',
                disableSelectInfo: true, // disable the select info panel on top
                selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
              }},on:{"on-page-change":params => this.query.page = params.currentPage,"on-row-click":params => this.$router.push({name: 'raw-materials-slug', params: {itemId: params.row.id}}),"on-per-page-change":params => this.query.limit = params.currentPerPage}},[_c('div',{attrs:{"slot":"emptystate"},slot:"emptystate"},[_vm._v(" Δεν βρέθηκαν πρώτες ύλες ")])]):_vm._e()],1)])]),(_vm.newRawMaterial)?_c('b-modal',{ref:"update-create-raw-material",staticClass:"form-modal",attrs:{"no-close-on-backdrop":"","ok-title":_vm.newRawMaterial.id ? 'Επεξεργασία' : 'Δημιουργία',"cancel-title":"Ακύρωση","title":(_vm.newRawMaterial.id ? 'Επεξεργασία' : 'Δημιουργία') + ' πρώτης ύλης'},on:{"ok":function($event){$event.preventDefault();return _vm.createOrUpdateMaterial.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('label',[_vm._v("Όνομα πρώτης ύλης")]),_c('b-form-input',{attrs:{"placeholder":"π.χ. Πατάτες"},model:{value:(_vm.newRawMaterial.name),callback:function ($$v) {_vm.$set(_vm.newRawMaterial, "name", $$v)},expression:"newRawMaterial.name"}})],1)])]):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-auto"},[_c('h2',[_vm._v("Πρώτες ύλες")])])
}]

export { render, staticRenderFns }