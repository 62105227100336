<template>
  <section id="sidebar" class="d-none d-md-block" :class="$store.state.sidebarToggled ? 'toggled' : ''">


    <div class="sidebar-wrap relative text-center">
      <router-link :to="{name:'home'}">
        <img src="/favicon.svg" width="45" class="mb-5 mt-4 mx-auto"/>
      </router-link>

      <div class="text-left">
        <router-link v-for="item in $store.state.menu" :key="item.url" class="btn btn-link d-block
       text-center" :to="{name: item.url}"
                     @click.native="fullScreenMenu = false">

          <span class="feather" :class="item.icon"/>
          <p class="text-sm mt-1 mb-0 font-weight-normal">{{item.title}}</p>
        </router-link>
      </div>
    </div>


  </section>
</template>

<script>

import {mapState} from "vuex";

export default{

  computed:{
    ...mapState({
      user: state => state.user,
      isLoggedIn: state => state.isLoggedIn
    })
  },
  methods:{
    redirectTo(url){
      this.$router.push({name: url});
    }
  },

  data(){
    return{
    }
  }

}
</script>
<style lang="scss">
#sidebar{
  overflow-y:auto;
  border-right:1px solid $primary_light;
  .sidebar-wrap{
    overflow-y:auto;
  }
  position:fixed;
  top:0;
  left:0;
  background:$secondary;
  bottom:0;
  z-index:10;
  width:85px;
  &.toggled{
    width:120px;
  }

  .btn-link{
    padding:10px 0!important;
    text-decoration: none!important;
    border-radius:0;
    &.router-link-active, &:hover{

      background:$primary;
      color:white;
    }
  }

}
</style>