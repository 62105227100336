import slugify from "slugify";
export default{

    data(){
        return{
            variations: ['regular', 'small', 'large'],
            variationLabels: {
                regular: 'Κανονική μερίδα',
                small: 'Μικρή μερίδα',
                large: 'Μεγάλη μερίδα'
            },
            mealTypes: ['breakfast', 'lunch', 'dinner', 'snack', 'desert', 'grab_go_dinner'],
            mealLabels:{
                breakfast: 'Πρωινό',
                lunch: 'Μεσημεριανό',
                dinner: 'Βραδινό',
                snack: 'Σνακ / Ενδιάμεσα',
                grab_go_dinner: 'Grab & Go Βραδινά',
                desert: 'Επιδόρπια'
            },
            sexes: {
                'M': 'Άνδρας',
                'F': 'Γυναίκα'
            },
            dayOptions: [5,6,7],
            vatRate: 1.13
        }
    },
    methods:{
        getPriceWithVat(price){

            try{
                let result = +(parseFloat(price) * this.vatRate).toFixed(2);
                return isNaN(result) ? 0 : result
            }catch(e){
                return '-'
            }
        },
        redirectTo(name, params){

            if(params) return this.$router.push({name: name, params: params});
            return this.$router.push({name: name})
        },

        slugFromName(name){

            return slugify(name, {
                lower: true,
                strict: true,
                trim: true
            })
        },
        formatDate(date) {
            if(!date) return null;
            let year = date.getFullYear();
            let month = (1 + date.getMonth()).toString().padStart(2, '0');
            let day = date.getDate().toString().padStart(2, '0');

            return `${day}/${month}/${year}`;
        },
        formatDateWithTime(date){
            if(!date) return null;

            let dateStr = this.formatDate(date);

            let timeStr = date.getHours() + ':' + date.getMinutes().toString().padStart(2, '0');

            return `${dateStr} ${timeStr}`
        },
        fixUrlQuery(key, value){
            let query = {...this.$route.query}

            if(!value) delete query[key]
            else query[key] = value;
            this.$router.push({query})
        },
    }
}