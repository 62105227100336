<template>

  <div id="products">

    <div class="container-large">
      <div class="row align-items-center">
        <div class="col-auto">
          <h2>Προϊόντα</h2>
        </div>
        <div class="col-auto ml-auto ext-right">
          <b-dropdown size="sm" variant="primary" class="mr-2" text="Ενέργειες">
            <b-dropdown-item @click="exportProductPrices">Εξαγωγή τιμών προϊόντων</b-dropdown-item>
            <b-dropdown-item @click="()=> this.$refs['import-csv'].show()">Εισαγωγή τιμών</b-dropdown-item>
          </b-dropdown>
          <b-button class="ml-auto" size="sm" variant="primary" @click="redirectTo('products-create')">Δημιουργία νέου</b-button>

        </div>
      </div>
    </div>

    <div class="container-large my-3">

      <div class="row">

        <div class="col-12 col-md-6">
          <b-form-input v-model="query.search" debounce="500" placeholder="Αναζήτηση προϊόντων (όνομα, barcode)"></b-form-input>
        </div>
      </div>
    </div>


    <div class="container-large mt-5">
      <vue-good-table
          ref="orders-table"
          mode="remote"
          :pagination-options="{
                enabled: true,
                total: totalProducts,
                setCurrentPage: this.query.page,
                dropdownAllowAll: false,
                perPage: this.query.limit,
                rowsPerPageLabel: 'Ανά σελίδα',
                nextLabel:'Επόμενη',
                prevLabel: 'Προηγούμενη',
                perPageDropdown: [10, 15, 20, 25],
              }"
          :totalRows="totalProducts"
          :sort-options="{ enabled: false }"
          style-class="vgt-table striped condensed"
          theme="polar-bear"
          @on-page-change="params => this.query.page = params.currentPage"
          @on-row-click="params => this.$router.push({name: 'edit-product', params: {itemId: params.row.id}})"
          @on-per-page-change="params => this.query.limit = params.currentPerPage"
          :columns="columns"
          :rows="products"
          :select-options="{
                  enabled: false,
                  selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
                  selectionInfoClass: 'custom-class',
                  selectionText: 'rows selected',
                  clearSelectionText: 'clear',
                  disableSelectInfo: true, // disable the select info panel on top
                  selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
                }">
          <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field === 'main_image'">

                  <div class="rounded-lg" style="width:60px;height:60px;background-repeat:no-repeat;background-position: center;background-size:cover"
                       :style="`background-image:url('${props.row.main_image}')`"></div>

                </span>
                <div v-else-if="props.column.field === 'categories'" class="d-flex">

                  <div v-for="cat in props.row.categories" :key="`${props.row.id}-cat-${cat}`" class="bg-primary flex-shrink-0 mx-1 text-white text-sm px-2 py-1 rounded">
                    {{$store.state.categoryLabels[cat]}}

                  </div>
                </div>
                <div v-else-if="props.column.field === 'cycles'" class="d-flex">

                  <div v-for="cycle in props.row.cycles" :key="`${props.row.id}-cat-${cycle}`" class="bg-primary flex-shrink-0 mx-1 text-white text-sm px-2 py-1 rounded">
                    {{cycle}}

                  </div>
                </div>
              <div v-else-if="props.column.field === 'seasons'" class="d-flex">

                <div v-for="season in props.row.seasons" :key="`${props.row.id}-cat-${season}`" class="bg-primary flex-shrink-0 mx-1 text-white text-sm px-2 py-1 rounded">
                  {{seasonTranslations[season]}}

                </div>
              </div>
                <span v-else>
                  {{props.formattedRow[props.column.field]}}
                </span>
          </template>
        <div slot="emptystate">Δεν βρέθηκαν προϊόντα</div>
      </vue-good-table>


      <b-modal ref="import-csv" title="Εισαγωγή από CSV" hide-footer>

        <p>
          Παρακαλώ εισάγετε ένα CSV με την ίδια μορφή που το εξάγατε.
        </p>
        <upload :max-files="1" :url="csvUploadRoute" @uploadCompleted="uploadCompleted"></upload>
      </b-modal>
    </div>
  </div>

</template>

<script>
import Upload from "@/components/upload";
export default{
  components: {Upload},

  computed:{
    csvUploadRoute(){
      return process.env.VUE_APP_API_URL + '/admin/products/csv-update-prices'
    }
  },

  data(){
    return{
      products: [],
      totalProducts: 0,
      query:{
        limit: 10,
        page: 1,
        search: ''
      },
      seasonTranslations: {
        winter: 'Χειμερινή',
        summer: 'Καλοκαιρινή'
      },
      columns: [
        {label: '#Id', field: 'id'},
        {label: 'Εικόνα', field: 'main_image'},
        {label: 'Όνομα', field: 'name'},
        {label: 'Τιμή', field: 'price', formatFn: (val) => (val ? val.toFixed(2) : '0.00') + '€'},
        {label: 'Τιμή (μικρή)', field: 'price_small', formatFn: (val) => (val ? val.toFixed(2) + '€' : '-') },
        {label: 'Τιμή (μεγάλη)', field: 'price_large', formatFn: (val) => (val ? val.toFixed(2) + '€' : '-') },
        {label: 'Κατηγορίες', field: 'categories'},
        {label: 'Κύκλοι', field: 'cycles'},
        {label: 'Σεζόν', field: 'seasons'}
       ]
    }
  },

  watch:{

    'query.page'(){
      this.getProducts();
    },

    'query.search'(){
      this.query.page = 1;
      this.getProducts()
    }

  },

  mounted(){

    this.getProducts()
    if(this.$route.query.page) {
      this.query.page = this.$route.query.page
    }
  },

  methods:{

    exportProductPrices(){

      return this.axios.get('/admin/products/csv-export').then(res=>{
        const hiddenElement = document.createElement('a');
        hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(res.data);
        hiddenElement.target = '_blank';
        hiddenElement.download = 'dreamdiet_product_prices.csv';
        hiddenElement.click();
      }).catch(()=>{
        this.$notify({type: 'error', title:'Something went wrong', text: 'Please try again later!', position: 'bottom center'});

      })
    },
    getProducts(){

      let query = {...this.query};

      query.offset = (query.page-1) * query.limit

      this.axios.get('/admin/products', {params: query}).then(res=>{
        this.products = res.data.rows;
        this.totalProducts = res.data.count;
      }).catch(()=>{
        this.$notify({type: 'error', title:'Something went wrong', text: 'Please try again later!', position: 'bottom center'});
      })
    },

    uploadCompleted(){
      this.$refs['import-csv'].hide();
      this.getProducts();
    }
  },

}
</script>