
<template>
  <div class="p-3">


    <b-form-checkbox v-model="notification.show" id="show-notification" name="show-notification" class="flex align-items-center">
      Ενεργοποίηση γενικής ειδοποίησης στο site
    </b-form-checkbox>

    <div v-if="notification.show" class="mt-3">
      <label>Κείμενο ειδοποίησης</label>
      <b-form-input v-if="notification.show" v-model="notification.text" placeholder="π.χ. Θα παραμείνουμε κλειστοί έως τις..."/>
    </div>

    <div class="mt-4 text-right">
      <b-button variant="primary" @click="saveChanges" :disabled="!hasChanged">Αποθήκευση αλλαγών</b-button>
    </div>

  </div>

</template>

<script>
export default{

  computed:{
    hasChanged(){

      if(this.originalData) return JSON.stringify(this.notification) !== this.originalData;

      return false;
    }
  },

  mounted(){

    this.getNotificationSettings();
    this.originalData = JSON.stringify(this.notification);


  },
  methods:{
    getNotificationSettings(){

      this.axios.get('/admin/settings/global_notification').then(res=>{
        console.log(res);
        if(res.data){
          this.notification = {...res.data.value};
          this.originalData = JSON.stringify(this.notification);
        }
      })

    },
    saveChanges(){

      this.axios.put('/admin/settings/global_notification', {value: this.notification}).then(res=>{

        if(res.data){
          this.notification = {...res.data.value};
          this.originalData = JSON.stringify(this.notification);
        }

      }).catch(e=>{
        console.log(e);
      })

    }
  },
  data(){
    return{
      notification:{
        show: false,
        text: ''
      },
      originalData: null
    }
  },


}
</script>