<template>

  <div class="upload">
    <vue-dropzone v-if="loaded" ref="myVueDropzone" id="dropzone"
                  :options="dropzoneOptions"
    @vdropzone-success="imageUploaded"
                  :use-custom-slot="true"
    >

      <div class="dz-message" data-dz-message><span class="feather icon-upload-cloud" style="font-size:30px;"/><br>Σύρτε ένα αρχείο ή κάντε κλικ και επιλέξτε</div>
    </vue-dropzone>

  </div>
</template>

<script>

import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import {mapState} from "vuex";

export default{

  props:{
    maxFiles: {
      type: Number,
      default: 10
    },
    url:{
      type: String,
      default: process.env.VUE_APP_API_URL + '/general/image-upload'
    }
  },

  components:{vueDropzone: vue2Dropzone},

  computed:{
    ...mapState({
      token: state => state.token
    })
  },
  methods:{
    imageUploaded(file, url){

      this.$emit('uploadCompleted');

      this.$emit('addedImage', url)
    }
  },
  mounted(){
    this.dropzoneOptions = {
      maxFiles: this.maxFiles,
      thumbnailWidth: 150,
      url: this.url,
      maxFilesize: 2,
      headers:{
        'x-access-token': this.token
      }
    };
    this.loaded = true;

  },
  data(){
    return{
      loaded: false,
      dropzoneOptions: {}
    }
  }
}
</script>

<style lang="scss">
#dropzone{
  border-width:1px;
  border-radius:12px;
}
</style>