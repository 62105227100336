<template>

  <div id="product-details">

    <div class="row mb-4">
      <div class="col-auto">
        <h3>{{ isEditMode? 'Επεξεργασία προϊόντος - ' + product.name : 'Δημιουργία προϊόντος' }}</h3>
      </div>
      <div class="col-auto ml-auto">
<!--        <b-button v-if="isEditMode" variant="danger" size="sm" class="mr-3" @click="deleteProduct">Διαγραφή</b-button>-->
      </div>

    </div>


    <div class="row">
      <div class="col-12 col-md-9">
        <div class=" p-2 rounded">

          <b-tabs>
            <b-tab title="Περιγραφή προϊόντος">
              <div class="row  mt-4">
                <div class="col-12 text-left">
                  <label>Εικόνα προϊόντος</label>
                  <div v-if="data.main_image">
                    <image-preview width="120" height="120" :url="data.main_image"/>
                    <b-button variant="link" @click="editFeaturedImage = !editFeaturedImage">Αλλαγή εικόνας</b-button>

                  </div>
                  <upload-area v-if="editFeaturedImage || !data.main_image" :max-files="1" @addedImage="addedFeaturedImage" />
                </div>

                <div class="col-12 col-md-6">
                  <label>Κατηγορίες προϊόντος</label>
                  <multiselect multiple v-model="data.categories" :options="Object.keys(categories[0])"
                               :customLabel="customLabel"></multiselect>
                </div>

                <div v-if="$store.state.filters" class="col-12 col-md-6">
                  <label>{{ $store.state.extraAttributes.cycles.title }}</label>
                  <multiselect multiple v-model="data.cycles" :options="$store.state.extraAttributes.cycles.options"
                               ></multiselect>
                </div>
                <div v-if="$store.state.filters" class="col-12">
                  <label>{{ $store.state.extraAttributes.seasons.title }}</label>
                  <multiselect multiple
                               v-model="data.seasons"
                               :options="$store.state.extraAttributes.seasons.options"
                               :custom-label="opt => seasonTranslations[opt] || opt" />
                </div>

                <div class="col-12 col-md-6">
                  <label>Όνομα προϊόντος</label>
                  <b-form-input v-model="data.name" placeholder="Όνομα προϊόντος" @change="slugify"/>
                </div>
                <div class="col-12 col-md-6">
                  <label>Σύνδεσμος προϊόντος</label>
                  <b-form-input v-model="data.slug"  :disabled="isEditMode"  placeholder="Σύνδεσμος (url link)"/>
                </div>

                <div class="col-12 col-md-4">
                  <label>Τιμή κανονικής μερίδας (χωρίς Φ.Π.Α.) <br><span class="font-weight-normal">(Με Φ.Π.Α.: {{getPriceWithVat(data.price)}}€)</span></label>
                  <b-form-input v-model="data.price"  placeholder="Τιμή προϊόντος χωρίς το νόμισμα (π.χ. 5.30)"/>
                  <p class="font-weight-light text-sm mt-2 mb-0">Η τιμή αφορά το κανονικό μέγεθος. Συμπληρώστε μόνο αυτό για 1 μέγεθος.</p>
                  <p v-if="productRawMaterialCosts.regular">(Κόστος πρώτων υλών: {{productRawMaterialCosts.regular.toFixed(2)}}€)</p>
                </div>
                <div class="col-12 col-md-4">
                  <label>Τιμή μικρής μερίδας (χωρίς Φ.Π.Α.) <br><span class="font-weight-normal">(Με Φ.Π.Α.: {{getPriceWithVat(data.price_small)}}€)</span></label>

                  <b-form-input v-model="data.price_small"  placeholder="Τιμή προϊόντος χωρίς το νόμισμα (π.χ. 5.30)"/>
                  <p class="font-weight-light text-sm mt-2 mb-0">Αφήστε κενό αν δεν θέλετε μικρή μερίδα. Εάν το συμπληρώσετε θα ενεργοποιηθεί η μικρή μερίδα.</p>
                  <p v-if="productRawMaterialCosts.small">(Κόστος πρώτων υλών: {{productRawMaterialCosts.small.toFixed(2)}}€)</p>
                </div>
                <div class="col-12 col-md-4">
                  <label>Τιμή μεγάλης μερίδας (χωρίς Φ.Π.Α.) <br><span class="font-weight-normal">(Με Φ.Π.Α.: {{getPriceWithVat(data.price_large)}}€)</span></label>
                  <b-form-input v-model="data.price_large"  placeholder="Τιμή προϊόντος χωρίς το νόμισμα (π.χ. 5.30)"/>
                  <p class="font-weight-light text-sm mt-2 mb-0">Αφήστε κενό αν δεν θέλετε μεγάλη μερίδα. Εάν το συμπληρώσετε θα ενεργοποιηθεί η μεγάλη μερίδα.</p>
                  <p v-if="productRawMaterialCosts.large">(Κόστος πρώτων υλών: {{productRawMaterialCosts.large.toFixed(2)}}€)</p>
                </div>


                <div class="col-12">

                  <b-button v-b-toggle.collapse-1 variant="link">Ιδιότητες (βάρος, ενέργεια, πρωτείνες κ.α.)</b-button>
                  <div>
                    <b-collapse id="collapse-1" class="mt-2">
                      <b-card>
                        <div class="col-12">
                          <div v-for="(variation) in activeVariations" :key="variation" class="mb-2">

                           <label class="block w-100 border-bottom">        {{variationLabels[variation]}}</label>

                            <div class="d-flex " style="gap:15px;">
                              <div >
                                <label>Βάρος (gr)</label>
                                <b-form-input v-model="data.weight[variation]"  />
                              </div>
                              <div >
                                <label>Πρωτεϊνες</label>
                                <b-form-input v-model="data.proteins[variation]"  />
                              </div>
                              <div >
                                <label>Υδατάνθρακες</label>
                                <b-form-input v-model="data.carbs[variation]"  />
                              </div>
                              <div >
                                <label>Λίπη</label>
                                <b-form-input v-model="data.fats[variation]"  />
                              </div>

                              <div >
                                <label>Θερμίδες</label>
                                <b-form-input v-model="data.calories[variation]"  />
                              </div>
                            </div>



                          </div>
                        </div>
                      </b-card>
                    </b-collapse>
                  </div>
                </div>







                <div class="col-12 col-md-6 text-left">
                  <label>Συστατικά</label>

                  <ckeditor :editor="editor" v-model="data.materials" :config="editorConfig"></ckeditor>
                </div>
                <div class="col-12 col-md-6 text-left">
                  <label>Περιγραφή</label>
                  <ckeditor :editor="editor" v-model="data.description" :config="editorConfig"></ckeditor>
                </div>


                <div class="col-12 text-left">
                  <label>Εικόνες προϊόντος</label>
                  <b-button variant="link" @click="addGalleryImages = !addGalleryImages">{{ addGalleryImages ? 'Κλείσιμο' : 'Προσθήκη εικόνων' }}</b-button>
                  <br>

                  <draggable v-model="data.gallery_images" @change="saveImageReOrdering">
                    <div v-for="(image, index) in data.gallery_images" :key="'image-'+index" class="d-inline-block position-relative" style="cursor:move">
                      <image-preview width="120" height="120" :url="image" class="mr-2"/>
                      <b-button variant="danger" class="px-2 py-0 m-0 position-absolute " style="bottom:10px;right:10px;"
                                @click="removeImage(image)"
                      ><span class="icon-trash" style="font-size:0.7rem;"></span></b-button>
                    </div>
                  </draggable>

                  <upload-area v-if="addGalleryImages" :max-files="10" @addedImage="addedGalleryImage" />
                </div>
              </div>
            </b-tab>


            <b-tab title="Πρώτες ύλες">
              <div class="row mt-4">
                <div class="col-12">
                  <div class="text-right">
                    <b-button size="xs" variant="primary" class="mb-3" @click="openRawMaterialsModal">Προσθήκη νέας πρώτης ύλης</b-button>
                  </div>

                  <vue-good-table
                      ref="materials-table"
                      mode="remote"
                      :pagination-options="{
                      enabled: false
                    }"
                      :totalRows="data.raw_materials.length"
                      :sort-options="{ enabled: false }"
                      style-class="vgt-table striped condensed"
                      theme="polar-bear"
                      :columns="rawMaterialColumns"
                      :rows="data.raw_materials">
                          <template slot="table-row" slot-scope="props">

                                <span v-if="props.column.field === 'actions'">

                                    <b-button v-if="props.row.product_raw_material.id" variant="warning" size="xs" class="mr-3" @click="openEditMaterialModal(props.row)">
                                      <span class="icon-edit"></span>
                                    </b-button>

                                  <b-button variant="danger" size="xs" @click="deleteRawMaterial(props.row)"><span class="icon-trash"></span></b-button>
                                </span>
                                  <span v-else>
                                    {{props.formattedRow[props.column.field]}}
                                  </span>
                          </template>

                    <div slot="emptystate">
                      Δεν βρέθηκαν πρώτες ύλες
                    </div>
                  </vue-good-table>
                </div>

              </div>
            </b-tab>


            <b-tab title="Φίλτρα προϊόντος">

              <div class="my-3">
                <div v-if="data.metadata && $store.state.filters && data.metadata.allergens">
                  <label>{{$store.state.filters.allergens.title}}</label>
                  <multiselect track-by="key" label="title"
                               multiple
                               placeholder="Επιλέξτε 1 ή περισσότερα..."
                               v-model="data.metadata.allergens"
                               :options="$store.state.filters.allergens.options"></multiselect>
                </div>

                <div v-if="data.metadata && $store.state.filters && data.metadata.foods_included">
                  <label>{{$store.state.filters.foods_included.title}}</label>
                  <multiselect track-by="key" label="title"
                               multiple
                               placeholder="Επιλέξτε 1 ή περισσότερα..."
                               v-model="data.metadata.foods_included"
                               :options="$store.state.filters.foods_included.options"></multiselect>
                </div>

                <div v-if="data.metadata && $store.state.filters && data.metadata.nutrition_types">
                  <label>{{$store.state.filters.nutrition_types.title}}</label>
                  <multiselect track-by="key" label="title"
                               multiple
                               placeholder="Επιλέξτε 1 ή περισσότερα..."
                               v-model="data.metadata.nutrition_types"
                               :options="$store.state.filters.nutrition_types.options"></multiselect>
                </div>

<!--                <div v-if="data.metadata && $store.state.filters && data.metadata.types">-->
<!--                  <label>{{$store.state.filters.types.title}}</label>-->
<!--                  <multiselect track-by="key" label="title"-->
<!--                               multiple-->
<!--                               placeholder="Επιλέξτε 1 ή περισσότερα..."-->
<!--                               v-model="data.metadata.types"-->
<!--                               :options="$store.state.filters.types.options"></multiselect>-->
<!--                </div>-->

              </div>



            </b-tab>
          </b-tabs>

        </div>

      </div>
      <div class="col-12">
        <div class="my-3 text-right">
          <b-button :variant="isEditMode ? 'success' :'primary'" :disabled="!canCreateOrUpdate" @click="createProduct">
            {{ isEditMode ? 'Αποθήκευση αλλαγών': 'Δημιουργία προϊόντος' }}</b-button>
        </div>
      </div>
    </div>

    <b-modal v-if="newProductMaterial" no-close-on-backdrop ref="raw-materials-modal"
             :ok-title="newProductMaterial.id ? 'Αποθήκευση αλλαγών' : 'Προσθήκη'"
             cancel-title="Ακύρωση"
             class="form-modal" :title="(newProductMaterial.id ? 'Επεξεργασία' : 'Προσθήκη') + ' πρώτης ύλης'"
             @ok.prevent="createOrUpdateMaterial">

      <div class="row form-row">
        <div v-if="!newProductMaterial.id" class="col-12">
          <label>Επιλογή πρώτης ύλης</label>
          <multiselect track-by="name" label="name" v-model="newProductMaterial.material"
                       placeholder="Επιλέξτε 1 ή περισσότερες..."
                       :options="rawMaterialList"></multiselect>
        </div>
        <div class="col-12">
          <label>Ποσότητα πρώτης ύλης (μεσαία μερίδα)</label>
          <b-form-input v-model="newProductMaterial.quantity" placeholder="π.χ. 100"></b-form-input>
        </div>
        <div class="col-12">
          <label>Ποσότητα πρώτης ύλης (μικρή μερίδα)</label>
          <b-form-input v-model="newProductMaterial.quantity_small" placeholder="π.χ. 100"></b-form-input>
        </div>
        <div class="col-12">
          <label>Ποσότητα πρώτης ύλης (μεγάλη μερίδα)</label>
          <b-form-input v-model="newProductMaterial.quantity_large" placeholder="π.χ. 100"></b-form-input>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
@import "~vue-wysiwyg/dist/vueWysiwyg.css";
.multiselect__input{
  background: transparent;
}
#product-details{

  .col-12{
    margin-bottom:1rem;
  }
}
</style>
<script>
import draggable from "vuedraggable"
import upload from "@/components/upload";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import Vue from "vue";
import Multiselect from 'vue-multiselect'
// import {SimpleUploadAdapter} from "@ckeditor/ckeditor5-upload";
export default{
  props:{
    product: Object
  },
  components: {uploadArea: upload, draggable, Multiselect},

  computed:{
    activeVariations(){
      return this.variations.filter(x => {

        if(x === 'regular') return true;
        return !!this.data['price_'+x] && !isNaN(this.data['price_'+x]) && this.data['price_'+x] > 0;
      }).sort((a) => {
        return a === 'large' ? -1 : a === 'regular' ? 0 : 1
      })
    },
    canCreateOrUpdate(){

      let canUpdate = this.data.name && this.data.slug && this.data.main_image;

      // we also check if we changed any data!
      if(this.isEditMode) canUpdate = canUpdate && this.hasChangedData

      return canUpdate;
    },
    productRawMaterialCosts(){

      let costs = {
        small: 0,
        regular: 0,
        large: 0
      }

      for(let material of this.data.raw_materials){

        if(this.data.price_small) costs.small += material.price_per_unit * material.product_raw_material.quantity_small
         costs.regular += material.price_per_unit * material.product_raw_material.quantity
        if(this.data.price_large) costs.large += material.price_per_unit * material.product_raw_material.quantity_large
      }


      return costs
    },
  },
  watch:{
    data: {
      handler(){
        if(!this.isLoaded) return;
        this.hasChangedData = true;
      },
      deep: true
    },

    product(){
      this.data = {...this.product}
      this.fixBadKeyNesting();

     this.getProductFilters();
    }

  },
  data(){
    return{
      editor: ClassicEditor,
      editorConfig: {
        // plugins: [SimpleUploadAdapter],
        simpleUpload: {
          uploadUrl:  process.env.VUE_APP_API_URL + '/general/image-upload',
        }
        // The configuration of the editor.
      },
      seasonTranslations: {
        winter: 'Χειμερινή',
        summer: 'Καλοκαιρινή'
      },
      productList: [],
      isEditMode: false,
      hasChangedData: false,
      editFeaturedImage: false,
      addGalleryImages: false,
      isLoaded: false,
      data: {
        name: '',
        slug: '',
        description: '',
        price: 0,
        price_small: 0,
        price_large: 0,
        materials: '',
        main_image: null,
        gallery_images: [],
        barcode: null,
        raw_materials: [],
        categories: [],
        cycles: [],
        seasons: [],
        carbs: {
          small:0,
          regular:0,
          large: 0
        },
        fats: {
          small:0,
          regular:0,
          large: 0
        },
        proteins: {
          small:0,
          regular:0,
          large: 0
        },
        calories: {
          small:0,
          regular:0,
          large: 0
        },
        weight:{
          small:0,
          regular:0,
          large: 0
        },
      },
      categories: [],
      rawMaterialList: [],
      newProductMaterial:{
        id: null,
        material: null,
        quantity: null,
        quantity_small: null,
        quantity_large: null
      },
      rawMaterialColumns:[
        {label: 'Όνομα πρώτης ύλης', field: 'name'},
        {label: 'Lot Number', field: 'lot_number'},
        {label: 'Ποσότητα (μεσαία μερίδα)', field: 'product_raw_material.quantity'},
        {label: 'Ποσότητα (μικρή μερίδα)', field: 'product_raw_material.quantity_small'},
        {label: 'Ποσότητα (μεγάλη μερίδα)', field: 'product_raw_material.quantity_large'},
        {label: 'Ενέργειες', field: 'actions'}
      ],
    }
  },

  mounted(){

    this.getProducts();
    this.getProductCategories();
    this.getRawMaterials();

    if(this.product){
      this.data = {...this.product};
      this.fixBadKeyNesting();
      this.isEditMode = true;
      this.$nextTick(()=>{
        this.isLoaded= true;
      })
    }
    this.getProductFilters();

  },

  methods:{

    fixBadKeyNesting(){
      let baseKeys = ['calories','proteins', 'weight', 'carbs', 'fats'];
      for(let key of baseKeys){
        if(!this.data[key]){
          this.data[key] = {
            small:0,
            regular:0,
            large: 0
          }
        }
      }
    },

    fixArrayByKey(key){

      Vue.set(this.data.metadata, key, this.data.metadata[key].map(x => {
        let item = this.$store.state.filters[key].options.find(o => o.key === x);
        return item ? {...item} : null;
      }).filter(x => !!x));
    },

    setupProductMetaData(){
      if(!this.$store.state.filters) return;
      if(!this.data.metadata) Vue.set(this.data, 'metadata', {});

      for(let key of Object.keys(this.$store.state.filters)){
        if(!this.data.metadata[key]){
          Vue.set(this.data.metadata, key, []);
        }else{
          this.fixArrayByKey(key);
        }
      }
      this.$nextTick(()=>{
        this.hasChangedData = false;
      })

    },

    getProductFilters(){

      if(this.$store.state.filters){
        this.setupProductMetaData();
        return;
      }
      this.axios.get('/products/filters').then(res=>{
        for(let value of Object.values(res.data.filters)){
          value.options = value.options.map(x => ({
            key: x.key,
            title: x.admin_title || x.title
          }));
        }
        this.$store.commit('setFilters', res.data.filters);
        this.$store.commit('setExtraAttributes', res.data.attributes);
        this.setupProductMetaData();

      }).catch(()=>{
        this.$notify({type: 'error', title:'Κάτι πήγε λάθος', text: 'Δεν φόρτωσαν σωστά τα φίλτρα των προϊόντων'});

      })
    },
    getProductCategories(){
      this.axios.get('/products/categories').then(res=>{
        this.categories = Object.values(res.data);
      }).catch(()=>{
        this.$notify({type: 'error', title:'Κάτι πήγε λάθος', text: 'Δεν φόρτωσαν σωστά οι κατηγορίες προϊόντων'});
      })
    },
    getProducts(){
      this.axios.get('/products?limit=1000').then(res=>{
        this.productList = res.data.rows;
      }).catch(()=>{
        this.$notify({type: 'error', title:'Κάτι πήγε λάθος', text: 'Δεν φόρτωσαν σωστά οι κατηγορίες προϊόντων'});
      })
    },
    slugify(){
      if(this.isEditMode) return;
      this.data.slug = this.slugFromName(this.data.name);
    },

    addedFeaturedImage(url){
      this.data.main_image = url;
    },
    addedGalleryImage(url){
      this.data.gallery_images.push(url);
    },
    createProduct(){

      this.data.categories = this.data.categories.filter(x => !!x)

      if(this.data.seasons.length === 0){
        this.$notify({type: 'error', title:'Σφάλμα', text: 'Δεν έχετε συμπληρώσει σεζόν για το προϊόν'});
        return;
      }

      for(let key of Object.keys(this.data.metadata)){

        if(Array.isArray(this.data.metadata[key])){
          this.data.metadata[key] = this.data.metadata[key].map(x => x.key).filter(x => !!x);
        }

      }
      if(this.isEditMode) return this.updateProduct();
      this.axios.post('/admin/products', {product: this.data}).then(()=>{
        this.$notify({type: 'success', title:'Success', text: 'The product was created successfully.'});
        this.redirectTo('products');

      }).catch(()=>{
        this.$notify({type: 'error', title:'Something went wrong', text: 'Please make sure you have filled all the fields accordingly'});
      })
    },

    removeImage(image){
      this.data.gallery_images = this.data.gallery_images.filter( x=> x!==image);
    },

    updateProduct(){
      if(!this.product.id) return;
      this.axios.put('/admin/products/'+this.product.id, {product: this.data}).then((res)=>{
        this.$notify({type: 'success', title:'Επιτυχία', text: 'Το προϊόν αποθηκεύτηκε επιτυχώς.'});
        this.$emit('productUpdated', res.data);
        this.hasChangedData = false;
        this.setupProductMetaData();
      }).catch(()=>{
        this.$notify({type: 'error', title:'Αποτυχία', text: 'Κάτι πήγε λάθος!'});

      })
    },
    customLabel(val){
      return this.$store.state.categoryLabels[val];
    },

    saveImageReOrdering(){
      if(this.isEditMode) this.updateProduct();
    },
    deleteProduct(){
      this.$swal({
        title: 'Are you sure you want to delete this product?',
        text: "You won't be able to revert this!",
        showCancelButton: true,
        icon: 'warning',
        confirmButtonText: 'Yes, delete it!',
        denyButtonText: 'Do not delete it!'
      }).then(res =>{
        if(res.isConfirmed){
          this.axios.delete('/admin/products/'+this.product.id).then(()=>{
            this.$notify({type: 'success', title:'Success', text: 'The product was deleted successfully.'});
            this.redirectTo('products');
          }).catch(()=>{})
        }
      })
    },

    openRawMaterialsModal(){
      this.newProductMaterial.id = null;
      this.newProductMaterial.material = null;
      this.newProductMaterial.quantity = null;
      this.newProductMaterial.quantity_small = null;
      this.newProductMaterial.quantity_large = null;
      this.$refs['raw-materials-modal'].show();
    },

    openEditMaterialModal(rawMaterial){

      this.newProductMaterial.id = rawMaterial.product_raw_material.id;
      this.newProductMaterial.quantity = rawMaterial.product_raw_material.quantity;
      this.newProductMaterial.quantity_small = rawMaterial.product_raw_material.quantity_small;
      this.newProductMaterial.quantity_large = rawMaterial.product_raw_material.quantity_large;

      this.$refs['raw-materials-modal'].show();
    },
    getRawMaterials(){

      this.axios.get('/admin/materials?limit=100000').then(res=>{
        this.rawMaterialList = res.data.rows;
      }).catch(()=>{
        this.$notify({type: 'error', title:'Κάτι πήγε λάθος', text: 'Παρακαλώ δοκιμάστε ξανά αργότερα.'});
      });

    },
    async createOrUpdateMaterial(){


      if(!this.product){

        if(this.data.raw_materials.find(x => x.materialId === this.newProductMaterial.material.id)){
          this.$notify({type: 'error', title:'Κάτι πήγε λάθος', text: 'Έχετε ήδη εισάγει αυτή την πρώτη ύλη.!'});
          return;
        }

        this.data.raw_materials.push({
          name: this.newProductMaterial.material.name,
          materialId: this.newProductMaterial.material.id,
          product_raw_material:{
            quantity: this.newProductMaterial.quantity,
            quantity_small: this.newProductMaterial.quantity_small,
            quantity_large: this.newProductMaterial.quantity_large
          }});


        this.$nextTick(()=>{
          this.$refs['raw-materials-modal'].hide();
        });
        return;
      }

      try{
        if(this.newProductMaterial.id){
          await this.axios.put(`/admin/products/${this.product.id}/raw-materials/${this.newProductMaterial.id}`, {
            quantity: this.newProductMaterial.quantity,
            quantity_small: this.newProductMaterial.quantity_small,
            quantity_large: this.newProductMaterial.quantity_large
          });
        }else{
          await this.axios.post(`/admin/products/${this.product.id}/raw-materials/`, {
            productId: this.product.id,
            rawMaterialId: this.newProductMaterial.material.id,
            quantity: this.newProductMaterial.quantity,
            quantity_small: this.newProductMaterial.quantity_small,
            quantity_large: this.newProductMaterial.quantity_large
          });
        }
        this.$notify({type: 'success', title:'Επιτυχία', text: 'Η δημιουργία ολοκληρώθηκε επιτυχώς.'});
        this.$emit('refreshProduct');
        this.$refs['raw-materials-modal'].hide();


      }catch(e){
        this.$notify({type: 'error', title:'Κάτι πήγε λάθος', text: 'Παρακαλώ δοκιμάστε ξανά αργότερα.!'});
      }

    },

    deleteRawMaterial(rawMaterial){

      if(!this.product){
        this.data.raw_materials = this.data.raw_materials.filter(x => x.materialId !== rawMaterial.materialId);
        return;
      }
      this.axios.delete(`/admin/products/${this.product.id}/raw-materials/${rawMaterial.product_raw_material.id}`).then(()=>{
        this.$emit('refreshProduct');
        this.$notify({type: 'success', title:'Επιτυχία', text: 'Η διαγραφή ολοκληρώθηκε επιτυχώς.'});
      }).catch(() => {
        this.$notify({type: 'error', title:'Κάτι πήγε λάθος', text: 'Παρακαλώ δοκιμάστε ξανά αργότερα.!'});
      })
    }
  }
}
</script>