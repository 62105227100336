<template>
  <div id="default-layout">
    <Sidebar/>
    <Header/>
    <div class="router-view-area " :class="$store.state.sidebarToggled ? 'fully-opened-sidebar' : ''">

      <b-overlay :show="loading" rounded="sm">
        <router-view></router-view>
      </b-overlay>
    </div>
    <Footer/>
  </div>
</template>

<script>
import Header from "../components/Header";
import Footer from "../components/Footer";
import {mapState} from "vuex";
import Sidebar from "@/components/Sidebar";
export default {
  components: {Header, Footer, Sidebar},
  computed:{
    ...mapState({
      loading: state => state.loading
    })
  }
}

</script>
<style lang="scss">
.router-view-area{
  min-height:70vh;
  padding:5px;
  @media(min-width:768px){
    margin-left:105px;
  }
  &.fully-opened-sidebar{
    @media(min-width:768px){
      margin-left:120px;
    }
  }
}
</style>