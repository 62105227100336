<template>

  <div id="plans">

    <div class="container-large">
      <div class="row align-items-center">
        <div class="col-auto">
          <h2>Πλάνα</h2>
        </div>
        <div class="col-auto ml-auto ext-right">
          <b-button class="ml-auto" size="sm" variant="primary" @click="redirectTo('plans-create')">Δημιουργία νέου</b-button>

        </div>
      </div>
    </div>
    <div class="container-large my-3">

      <div class="row">

        <div class="col-12 col-md-6">
          <label class="small-label">Αναζήτηση</label>
          <b-form-input v-model="query.search" debounce="500" placeholder="Αναζήτηση πλάνων με το όνομα"></b-form-input>
        </div>
        <div class="col-6 col-md-3">
          <label class="small-label">Φύλο</label>
          <b-form-select v-model="query.sex" :options="{null: 'Προβολή όλων', ...sexes}"></b-form-select>
        </div>
        <div class="col-6 col-md-3">
          <label class="small-label">Ημέρες</label>
          <b-form-select v-model="query.days" :options="getDayOptions"></b-form-select>
        </div>
      </div>
    </div>


    <div class="container-large mt-5">
      <vue-good-table
          ref="orders-table"
          mode="remote"
          :pagination-options="{
                enabled: true,
                total: totalPlans,
                setCurrentPage: this.query.page,
                dropdownAllowAll: false,
                perPage: this.query.limit,
                rowsPerPageLabel: 'Ανά σελίδα',
                nextLabel:'Επόμενη',
                prevLabel: 'Προηγούμενη',
                perPageDropdown: [10, 15, 20, 25],
              }"
          :totalRows="totalPlans"
          :sort-options="{ enabled: false }"
          style-class="vgt-table striped condensed bordered"
          theme="polar-bear"
          @on-page-change="params => this.query.page = params.currentPage"
          @on-row-click="params => this.$router.push({name: 'edit-plan', params: {itemId: params.row.id}})"
          @on-per-page-change="params => this.query.limit = params.currentPerPage"
          :columns="columns"
          :rows="plans"
          :select-options="{
                  enabled: false,
                  selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
                  selectionInfoClass: 'custom-class',
                  selectionText: 'rows selected',
                  clearSelectionText: 'clear',
                  disableSelectInfo: true, // disable the select info panel on top
                  selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
                }">
          <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field === 'image'">

                  <div class="rounded-lg" style="width:60px;height:60px;background-repeat:no-repeat;background-position: center;background-size:cover"
                       :style="`background-image:url('${props.row.image}')`"></div>

                </span>
            <span v-else-if="props.column.field === 'sex'">
                  {{sexes[props.row.sex]}}
                </span>
                <span v-else-if="props.column.field === 'track_portion_size'">
                  {{props.row.track_portion_size ? '✅' : '❌'}}
                </span>
                <span v-else>
                  {{props.formattedRow[props.column.field]}}
                </span>
          </template>
        <div slot="emptystate">Δεν βρέθηκαν πλάνα</div>
      </vue-good-table>

    </div>
  </div>

</template>

<script>
export default{

  data(){
    return{
      plans: [],
      totalPlans: 0,
      query:{
        limit: 10,
        page: 1,
        search: '',
        sex: null,
        days: null
      },
      columns: [
        {label: 'Εικόνα', field: 'image'},
        {label: 'Τίτλος', field: 'title'},
        {label: 'Φύλο', field: 'sex'},
        {label: 'Ημέρες', field: 'days'},
        {label: 'Πρωινά', field: 'options.breakfast.total'},
        {label: 'Μεσημεριανά', field: 'options.lunch.total'},
        {label: 'Βραδινά', field: 'options.dinner.total'},
        {label: 'Grab & Go Βραδινά', field: 'options.grab_go_dinner.total'},
        {label: 'Επιδόρπια', field: 'options.desert.total'},
        {label: 'Σνακ / Ενδιάμεσα', field: 'options.snack.total'},
        {label: 'Μεγέθη μερίδων; ', field: 'track_portion_size'}
       ]
    }
  },

  computed:{
    getDayOptions(){
      let options = []
      options.push({text: 'Προβολή όλων', value: null});
      for(let day of this.dayOptions){
        options.push({text:day, value: day})
      }
      return options
    }

  },

  watch:{

    'query.page'(){
      this.getPlans();
    },

    'query.search'(){
      this.query.page = 1;
      this.getPlans()
    },
    'query.sex'(){
      this.query.page = 1;
      this.getPlans()
    },
    'query.days'(){
      this.query.page = 1;
      this.getPlans()
    }

  },

  mounted(){

    this.getPlans()
    if(this.$route.query.page) {
      this.query.page = this.$route.query.page
    }
    if(this.$route.query.days) {
      this.query.days = this.$route.query.days
    }
    if(this.$route.query.sex) {
      this.query.sex = this.$route.query.sex
    }
  },

  methods:{
    getPlans(){

      let query = {...this.query};

      query.offset = (query.page-1) * query.limit

      for(let key of Object.keys(query)){

        if(query[key] === 'null') delete query[key];
      }

      this.axios.get('/admin/plans', {params: query}).then(res=>{
        this.plans = res.data.rows;
        this.totalPlans = res.data.count;
      }).catch(()=>{
        this.$notify({type: 'error', title:'Something went wrong', text: 'Please try again later!', position: 'bottom center'});
      })
    },

    uploadCompleted(){
      this.$refs['import-csv'].hide();
      this.getPlans();
    }
  },

}
</script>