
<template>

  <div v-if="data" id="single-user">

    <div class="container">
      <div class="row align-items-center">
        <div class="col-auto">

          <b-button variant="primary" size="xs" class="mb-2" @click="$router.push({name: 'raw-materials'})"><span class="icon-arrow-left"></span> Επιστροφή στις πρώτες ύλες</b-button>
          <h2> {{data.name}}</h2>
        </div>
        <div class="col-auto ml-auto ext-right">

          <b-button variant="primary" @click="openCreateUpdateNewMaterialModal(null)">Προσθήκη stock</b-button>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="row mt-4">
        <div class="col-12">

          <vue-good-table
              v-if="loaded"
              ref="orders-table"
              mode="remote"
              :pagination-options="{
                enabled: true,
                total: totalCount,
                setCurrentPage: this.query.page,
                dropdownAllowAll: false,
                perPage: this.query.limit,
                rowsPerPageLabel: 'Ανά σελίδα',
                nextLabel:'Επόμενη',
                prevLabel: 'Προηγούμενη',
                perPageDropdown: [10, 15, 20, 25],
              }"
              :totalRows="totalCount"
              :sort-options="{ enabled: false }"
              style-class="vgt-table striped condensed"
              theme="polar-bear"
              @on-page-change="params => this.query.page = params.currentPage"
              @on-row-click="params => this.openCreateUpdateNewMaterialModal(params.row)"
              @on-per-page-change="params => this.query.limit = params.currentPerPage"
              :columns="columns"
              :rows="materialStocks"
              :select-options="{
                  enabled: false,
                  selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
                  selectionInfoClass: 'custom-class',
                  selectionText: 'rows selected',
                  clearSelectionText: 'clear',
                  disableSelectInfo: true, // disable the select info panel on top
                  selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
                }">
            <div slot="emptystate">
              Δεν βρέθηκαν πρώτες ύλες
            </div>
          </vue-good-table>
        </div>
      </div>
    </div>


    <b-modal v-if="newMaterialStock" no-close-on-backdrop ref="update-create-raw-material"
             :ok-title="newMaterialStock.id ? 'Αποθήκευση αλλαγών' : 'Δημιουργία'"
             cancel-title="Ακύρωση"
             @hidden="initializeMaterialStock"
             class="form-modal" :title="(newMaterialStock.id ? 'Επεξεργασία' : 'Δημιουργία νέας') + '  παραλαβής'"
             @ok.prevent="createOrUpdateMaterial">

      <div class="row form-row">
        <div class="col-12 col-md-6">
          <label>LOT Number</label>
          <b-form-input v-model="newMaterialStock.lot_number"></b-form-input>
        </div>
        <div class="col-12 col-md-6">

          <label>Ημερομηνία λήξης</label>
          <date-picker v-if="newMaterialStock.expiration_date" v-model="newMaterialStock.expiration_date"
                       value-type="YYYY-MM-DD"></date-picker>
        </div>
        <div class="col-12 col-md-6">
          <label>Ποσότητα</label>
          <b-form-input v-model="newMaterialStock.quantity"></b-form-input>
        </div>
        <div class="col-12 col-md-6">
          <label>Τιμή μονάδας</label>
          <b-form-input v-model="newMaterialStock.price_per_unit"></b-form-input>
        </div>
        <div class="col-12">
          <label>Σημειώσεις</label>
          <b-form-textarea v-model="newMaterialStock.description"></b-form-textarea>
        </div>
      </div>

    </b-modal>


  </div>
</template>
<style lang="scss">
.stock-notice{
  list-style-type:circle;
  padding-left:20px;
}
.user-detail-item{
  &:nth-child(even){
    background-color:$primary_light;
  }
  .title-label{
    padding-left:0.5rem;
    font-size:13px;
  }
  .title{
    font-size:14px;
  }
}
.user-item-wrap{
  buser:1px solid $primary_shade;
  margin-top:-1px;

  .title-label{
    font-size:9px;
  }
  .title{
    font-size:14px;
  }
  &:first-child{
    buser-top-left-radius: 12px!important;
    buser-top-right-radius: 12px!important;
  }
  &:last-child{
    buser-bottom-left-radius: 12px;
    buser-bottom-right-radius: 12px;
  }
  &:nth-child(even){
    background-color:$primary_light;
  }
}
.section-toggle{
  cursor:pointer;
}
</style>

<script>
import {getDayOfYear} from "date-fns"
import moment from "moment"

export default{
  data(){
    return{

      data: null,
      materialStocks: [],
      totalCount: 0,
      key: 0,
      query:{
        limit: 10,
        page: 1,
        search: ''
      },
      loaded: false,

      columns: [
        {label: 'Lot Number', field: 'lot_number'},
        {label: 'Ποσότητα', field: 'quantity'},
        {label: 'Τιμή μονάδας', field: 'price_per_unit'},
        {label: 'Ημερομηνία λήξης', field: 'expiration_date', type: 'date', dateInputFormat: 'yyyy-MM-dd', dateOutputFormat: 'dd-MM-yyyy'},
        {label: 'Περιγραφή', field: 'description'},
      ],
      newMaterialStock: {
        lot_number: '',
        description: '',
        quantity: '',
        price_per_unit: '',
        expiration_date: moment().add(1,'month').format('YYYY-MM-DD'), // default is 1 month in the future
        rawMaterialId: this.$route.params.itemId
      }
    }
  },
  watch:{
    'query.limit'(){
      this.getData();
    },

    'query.page'(val){
      if(!this.loaded) return;
      this.fixUrlQuery('page', val);
      this.getData();
    },
  },
  mounted(){
    this.getMaterial();
    this.getData();
    this.initializeMaterialStock();

  },
  methods:{

    getData(){

      let query = {...this.query};

      query.offset = (query.page-1) * query.limit

      this.axios.get('/admin/material-stock/material/' + this.$route.params.itemId, {params: query}).then(res=>{
        this.materialStocks = res.data.rows;
        this.totalCount = res.data.count;
        this.loaded = true;
      }).catch(()=>{
        this.$notify({type: 'error', title:'Something went wrong', text: 'Please try again later!'});
      })
    },

    getMaterial(){
      return this.axios.get('/admin/materials/'+this.$route.params.itemId).then(res=>{
        this.data = res.data;
      }).catch(()=>{
        this.$notify({type: 'error', title:'Something went wrong', text: 'Please try again later!'});
      })
    },

    openCreateUpdateNewMaterialModal(material){
      if(material) this.newMaterialStock = {...material};
      if(!this.newMaterialStock.expiration_date) this.newMaterialStock.expiration_date = moment().add(1, 'month').format('YYYY-MM-DD')
      this.$refs['update-create-raw-material'].show();
    },

    async createOrUpdateMaterial(){

      try{
        if(this.newMaterialStock.id){
          await this.axios.put('/admin/material-stock/'+this.newMaterialStock.id, this.newMaterialStock);
        }else{
          await this.axios.post('/admin/material-stock', this.newMaterialStock)
        }

        this.$notify({type: 'success', title:'Επιτυχία', text: 'Η δημιουργία ολοκληρώθηκε επιτυχώς.'});
        this.$refs['update-create-raw-material'].hide();

        this.getData();

      }catch(e){
        this.$notify({type: 'error', title:'Something went wrong', text: 'Please try again later!'});
      }

    },

    initializeMaterialStock(){
      let materialStock = {
        year: new Date().getFullYear(),
        month: new Date().getMonth(),
        day: new Date().getDate()
      };

      this.newMaterialStock = {
        lot_number: '',
        description: '',
        quantity: '',
        price_per_unit: '',
        expiration_date: moment().add(1,'month').format('YYYY-MM-DD'), // default is 1 month in the future
        rawMaterialId: this.$route.params.itemId
      }

      let day = getDayOfYear(new Date(materialStock.year, materialStock.month, materialStock.day));

      this.newMaterialStock.lot_number = '9' + (parseInt(materialStock.year) - 2000) + '' +this.formatToThreeDigits(day);

    },
    formatToThreeDigits(num) {
      if (num < 10) {
        return "00" + num;
      } else if (num < 100) {
        return "0" + num;
      }
      return num.toString();
    }
  }
}
</script>