<template>
  <div id="account" class="container">





    <div class="text-center">
      <h2>Καλωσόρισες, <span class="text-primary-gradient">{{user.firstName}} {{user.lastName}}</span></h2>
      <p>Θέλετε να αποσυνδεθείτε;  <a href="#" @click.prevent="logout">Αποσύνδεση</a></p>
    </div>


    <div v-if="userObj" class="account-page mt-4">

      <div class="container">

        <div class="row">
          <div class="col-12 col-md-6 text-left">

            <b-card>
              <b-card-title><h6>Το προφίλ σου</h6></b-card-title>
              <b-card-body>
                <b-form-input :value="userObj.firstName + ' '+ userObj.lastName" class="mb-2" disabled/>

                <b-form-input v-model="userObj.email" disabled/>
              </b-card-body>
            </b-card>


          </div>
        </div>

      </div>
    </div>
  </div>

</template>

<script>
import {mapState} from "vuex"
export default{


  computed:{
    ...mapState({
      user: state => state.user
    })
  },
  methods:{
    logout(){
      this.$store.dispatch("logout").then( () => {
        this.$router.push({name: 'home'})
      });
    }
  },
  data(){
    return{
      userObj: null
    }
  },
  mounted(){
    this.userObj = {...this.user};
  }
}
</script>