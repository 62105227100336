<template>

  <div id="new-plan">

    <div class="container">
      <plan-details/>
    </div>
  </div>
</template>


<script>
import PlanDetails from "@/components/PlanDetails";
export default{

  components: {PlanDetails}

}
</script>