<template>

  <div id="users">

    <div class="container-large">
      <div class="row align-items-center">
        <div class="col-auto">
          <h2>Πελάτες / Διαχειριστές</h2>
        </div>
        <div class="col-auto ml-auto text-right">
        </div>
      </div>
    </div>

    <div class="container-large my-3">

      <div class="row">

        <div class="col-12 col-md-4">
          <b-form-input v-model="query.search" debounce="500" placeholder="Αναζήτηση πελάτη..." class="py-2"></b-form-input>
        </div>
      </div>
    </div>

    <div class="container-large  mt-4">
      <div class="row">
        <div class="col-12">
          <vue-good-table
              v-if="loaded"
              ref="orders-table"
              mode="remote"
              :pagination-options="{
                enabled: true,
                total: totalUsers,
                setCurrentPage: this.query.page,
                dropdownAllowAll: false,
                perPage: this.query.limit,
                rowsPerPageLabel: 'Ανά σελίδα',
                nextLabel:'Επόμενη',
                prevLabel: 'Προηγούμενη',
                perPageDropdown: [10, 15, 20, 25],
              }"
              :totalRows="totalUsers"
              :sort-options="{ enabled: false }"
              style-class="vgt-table striped condensed"
              theme="polar-bear"
              @on-page-change="params => this.query.page = params.currentPage"
              @on-row-click="viewUser"
              @on-per-page-change="params => this.query.limit = params.currentPerPage"
              :columns="columns"
              :rows="users"
              :select-options="{
                  enabled: true,
                  selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
                  selectionInfoClass: 'custom-class',
                  selectionText: 'rows selected',
                  clearSelectionText: 'clear',
                  disableSelectInfo: true, // disable the select info panel on top
                  selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
                }">
            <!--          <template slot="table-row" slot-scope="props">-->
            <!--          </template>-->

            <div slot="emptystate">
              Δεν βρέθηκαν δεδομένα
            </div>
          </vue-good-table>
        </div>
      </div>
    </div>

  </div>

</template>
<style lang="scss">
.filters-wrap{
  overflow-y: auto;
  > div {
    font-size:14px;
  }
}
.status-filter{
  border-bottom:1px solid;
  border-color:transparent;
  &.active{
    font-weight:bold;
    border-bottom:1px solid;
    border-color: $primary;
  }
}
.user-item{
  cursor:pointer;
  margin-top:2px;
  &:nth-child(even){
    background-color:$primary_shade;
  }
  border-color:$primary_shade;
  &:hover{
    background-color:$primary_shade;
  }
  > div{
    font-size:13px;
    font-weight:500;
  }
}
</style>
<script>
import {mapState} from "vuex";
// import upload from "@/components/upload";

export default{

  data(){
    return{
      users: [],
      totalUsers: 0,
      query:{
        limit: 10,
        page: 1,
        search: '',
        role: null
      },
      loaded: false,
      columns:[
        {label: 'Όνομα', field: 'firstName'},
        {label: 'Επώνυμο', field: 'lastName'},
        {label: 'E-mail', field: 'email'},
        {label: 'Περιοχή', field: 'email'},
      ]
    }
  },
  components: {},
  computed:{
    ...mapState({
      user_roles: state => state.user_roles,
    }),
  },

  watch:{

    'query.page'(val){
      if(!this.loaded) return;
      this.fixUrlQuery('page', val);
      this.getUsers();
    },

    'query.role'(val){
      if(!this.loaded) return;
      this.query.page = 1;
      this.fixUrlQuery('role', val);
      this.getUsers()
    },

    'query.search'(val){
      if(!this.loaded) return;
      this.query.page = 1;
      this.fixUrlQuery('search', val);
      this.getUsers()
    }

  },

  mounted(){


    if(this.$route.query.page) this.query.page = this.$route.query.page
    if(this.$route.query.search) this.query.search = this.$route.query.search;
    if(this.$route.query.role) this.query.role = this.$route.query.role;


    this.getUsers()
  },

  methods:{

    fixUrlQuery(key, value){
      let query = {...this.$route.query}

      if(!value) delete query[key]
      else query[key] = value;
      this.$router.push({query})
    },
    getUsers(){

      let query = {...this.query};

      query.offset = (query.page-1) * query.limit

      this.axios.get('/admin/users', {params: query}).then(res=>{
        this.users = res.data.rows;
        this.totalUsers = res.data.count;
        this.loaded = true;
      }).catch(()=>{
        this.$notify({type: 'error', title:'Something went wrong', text: 'Please try again later!'});
      })
    },

    viewUser(event){
      console.log(event);


      this.$router.push({name: 'users-slug', params: {itemId: event.row.id}})
    }
  }

}
</script>