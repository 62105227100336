var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"plans"}},[_c('div',{staticClass:"container-large"},[_c('div',{staticClass:"row align-items-center"},[_vm._m(0),_c('div',{staticClass:"col-auto ml-auto ext-right"},[_c('b-button',{staticClass:"ml-auto",attrs:{"size":"sm","variant":"primary"},on:{"click":function($event){return _vm.redirectTo('plans-create')}}},[_vm._v("Δημιουργία νέου")])],1)])]),_c('div',{staticClass:"container-large my-3"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-6"},[_c('label',{staticClass:"small-label"},[_vm._v("Αναζήτηση")]),_c('b-form-input',{attrs:{"debounce":"500","placeholder":"Αναζήτηση πλάνων με το όνομα"},model:{value:(_vm.query.search),callback:function ($$v) {_vm.$set(_vm.query, "search", $$v)},expression:"query.search"}})],1),_c('div',{staticClass:"col-6 col-md-3"},[_c('label',{staticClass:"small-label"},[_vm._v("Φύλο")]),_c('b-form-select',{attrs:{"options":{null: 'Προβολή όλων', ..._vm.sexes}},model:{value:(_vm.query.sex),callback:function ($$v) {_vm.$set(_vm.query, "sex", $$v)},expression:"query.sex"}})],1),_c('div',{staticClass:"col-6 col-md-3"},[_c('label',{staticClass:"small-label"},[_vm._v("Ημέρες")]),_c('b-form-select',{attrs:{"options":_vm.getDayOptions},model:{value:(_vm.query.days),callback:function ($$v) {_vm.$set(_vm.query, "days", $$v)},expression:"query.days"}})],1)])]),_c('div',{staticClass:"container-large mt-5"},[_c('vue-good-table',{ref:"orders-table",attrs:{"mode":"remote","pagination-options":{
              enabled: true,
              total: _vm.totalPlans,
              setCurrentPage: this.query.page,
              dropdownAllowAll: false,
              perPage: this.query.limit,
              rowsPerPageLabel: 'Ανά σελίδα',
              nextLabel:'Επόμενη',
              prevLabel: 'Προηγούμενη',
              perPageDropdown: [10, 15, 20, 25],
            },"totalRows":_vm.totalPlans,"sort-options":{ enabled: false },"style-class":"vgt-table striped condensed bordered","theme":"polar-bear","columns":_vm.columns,"rows":_vm.plans,"select-options":{
                enabled: false,
                selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
                selectionInfoClass: 'custom-class',
                selectionText: 'rows selected',
                clearSelectionText: 'clear',
                disableSelectInfo: true, // disable the select info panel on top
                selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
              }},on:{"on-page-change":params => this.query.page = params.currentPage,"on-row-click":params => this.$router.push({name: 'edit-plan', params: {itemId: params.row.id}}),"on-per-page-change":params => this.query.limit = params.currentPerPage},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'image')?_c('span',[_c('div',{staticClass:"rounded-lg",staticStyle:{"width":"60px","height":"60px","background-repeat":"no-repeat","background-position":"center","background-size":"cover"},style:(`background-image:url('${props.row.image}')`)})]):(props.column.field === 'sex')?_c('span',[_vm._v(" "+_vm._s(_vm.sexes[props.row.sex])+" ")]):(props.column.field === 'track_portion_size')?_c('span',[_vm._v(" "+_vm._s(props.row.track_portion_size ? '✅' : '❌')+" ")]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])},[_c('div',{attrs:{"slot":"emptystate"},slot:"emptystate"},[_vm._v("Δεν βρέθηκαν πλάνα")])])],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-auto"},[_c('h2',[_vm._v("Πλάνα")])])
}]

export { render, staticRenderFns }