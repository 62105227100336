<template>

  <div id="product-details">

    <div class="row mb-4">
      <div class="col-auto">
        <h3>{{ isEditMode? 'Επεξεργασία πλάνου - ' + plan.title : 'Δημιουργία πλάνου' }}</h3>
      </div>
      <div class="col-auto ml-auto">
        <b-button v-if="isEditMode" variant="danger" size="sm" class="mr-3" @click="deletePlan">Διαγραφή</b-button>
      </div>

    </div>


    <div class="row">
      <div class="col-12 col-md-9">
        <div class=" p-2 rounded">
          <b-tabs>
            <b-tab title="Γενικά">
              <div class="row  mt-4">
                <div class="col-12 text-left">
                  <label>Εικόνα πλάνου</label>
                  <div v-if="data.image">
                    <image-preview width="240" height="120" :url="data.image"/>
                    <b-button variant="link" @click="editFeaturedImage = !editFeaturedImage">Αλλαγή εικόνας</b-button>

                  </div>
                  <upload-area v-if="editFeaturedImage || !data.image" :max-files="1" @addedImage="addedFeaturedImage" />
                </div>

                <div class="col-12 col-md-6">
                  <label>Όνομα πλάνου</label>
                  <b-form-input v-model="data.title" placeholder="Όνομα πλάνου"/>
                </div>

                <div class="col-12 col-md-6">
                  <label>Φύλο:</label>
                  <b-form-select v-model="data.sex" :options="sexes"></b-form-select>
                </div>
                <div class="col-12 col-md-6">
                  <label>Αρ. Ημερών:</label>
                  <b-form-select v-model="data.days" :options="dayOptions" @change="applyDifferentTotalPerOption"></b-form-select>
                </div>


                <div class="col-12 col-md-6 text-left">
                  <label>Περιγραφή</label>
                  <ckeditor :editor="editor" v-model="data.description" :config="editorConfig"></ckeditor>
                </div>


              </div>
            </b-tab>

            <b-tab title="Γεύματα ανά τύπο">



              <div class="row py-4">
                <div class="col-12">
                  <label>Ποσότητα γευμάτων</label>
                </div>
                <div v-for="type in mealTypes" :key="type" class="col-6 col-md-3">

                  <label>{{mealLabels[type]}}</label>
                  <b-form-input v-model="data.options[type].total" type="number" min="0" step="1"></b-form-input>
                </div>
              </div>


              <b-form-checkbox switch v-model="data.track_portion_size">Παρακολούθηση μεγέθους μερίδων</b-form-checkbox>


              <div v-if="data.track_portion_size">

                <b-alert show variant="info" class="my-4">
                  Αν αφήσετε 0 σε όλες τις μερίδες, δεν θα παρακολουθούνται τα μεγέθη για το συγκεκριμένο γεύμα.
                </b-alert>

                <div v-for="type in filledMealTypes" :key="type" class="row align-items-center py-2">

                  <div class="col-12 col-md-3">
                    {{mealLabels[type]}} ({{calculateVariationCount(type)}}/{{ data.options[type].total}})
                  </div>
                  <div v-for="variation in variations" :key="variation" class="col-md-3">
                    <label>{{variationLabels[variation]}}</label>
                    <b-form-input v-model="data.options[type][variation]" type="number" min="0" step="1"></b-form-input>
                  </div>
                </div>
              </div>
            </b-tab>
          </b-tabs>

        </div>
      </div>
      <div class="col-12 col-md-9">
        <b-alert v-if="!mealPlansProperlyAddedUp.status" variant="danger" show>

          ΠΡΟΣΟΧΗ! Έχετε ενεργοποιημένη την <strong>παρακολούθηση μεγεθών μερίδων</strong> αλλά το άθροισμα σας είναι λάθος στο <strong>{{mealLabels[mealPlansProperlyAddedUp.type]}}</strong>
        </b-alert>
        <div class="my-3 text-right">
          <b-button :variant="isEditMode ? 'success' :'primary'" :disabled="!canCreateOrUpdate" @click="createPlan">
            {{ isEditMode ? 'Αποθήκευση αλλαγών': 'Δημιουργία πλάνου' }}</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
@import "~vue-wysiwyg/dist/vueWysiwyg.css";
.multiselect__input{
  background: transparent;
}
#product-details{

  .col-12{
    margin-bottom:1rem;
  }
}
</style>
<script>
import upload from "@/components/upload";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default{
  props:{
    plan: Object
  },
  components: {uploadArea: upload},

  computed:{
    canCreateOrUpdate(){

      let canUpdate = this.data.title && this.data.sex && this.data.days && this.mealPlansProperlyAddedUp.status;

      // we also check if we changed any data!
      if(this.isEditMode) canUpdate = canUpdate && this.hasChangedData

      return canUpdate;
    },

    filledMealTypes(){
      return this.mealTypes.filter(x => parseInt(this.data.options[x].total) > 0)
    },

    mealPlansProperlyAddedUp(){

      if(!this.data.track_portion_size) return {
        status: true
      };

      for(let type of this.mealTypes){

        let sum =  this.calculateVariationCount(type);
        let total = parseInt(this.data.options[type].total);

        if(sum !== total && sum !== 0) return {
          status:false,
          type: type

        };
      }
      return {
        status: true
      };

    }
  },
  watch:{
    data: {
      handler(){
        if(!this.isLoaded) return;
        this.hasChangedData = true;
      },
      deep: true
    },

    plan(){
      this.data = {...this.plan}
    }

  },
  data(){
    return{
      editor: ClassicEditor,
      editorConfig: {
        // plugins: [SimpleUploadAdapter],
        simpleUpload: {
          uploadUrl:  process.env.VUE_APP_API_URL + '/general/image-upload',
        }
        // The configuration of the editor.
      },
      isEditMode: false,
      hasChangedData: false,
      editFeaturedImage: false,
      isLoaded: false,
      data: {
        title: null,
        days: 5,
        image: null,
        description: '',
        sex: 'M',
        track_portion_size: false,
        options: {
          breakfast: {
            small: 0,
            regular: 0,
            large: 0,
            total: 5
          },
          lunch: {
            small: 0,
            regular: 0,
            large: 0,
            total: 5
          },
          dinner: {
            small: 0,
            regular: 0,
            large: 0,
            total: 5
          },
          desert:{
            small: 0,
            regular: 0,
            large: 0,
            total: 5
          },
          grab_go_dinner:{
            small: 0,
            regular: 0,
            large: 0,
            total: 5
          },
          snack: {
            small: 0,
            regular: 0,
            large: 0,
            total: 5
          }
        }
      },
    }
  },

  mounted(){
    if(this.plan){
      this.data = {...this.plan};
      this.isEditMode = true;
      this.$nextTick(()=>{
        this.isLoaded= true;
      })
    }

  },

  methods:{

    calculateVariationCount(type){
      let sum = 0;
      for(let variation of this.variations){
        sum += parseInt(this.data.options[type][variation]);
      }
      return sum
    },
    addedFeaturedImage(url){
      this.data.image = url;
    },

    // applies the new active "total" for days.
    applyDifferentTotalPerOption(){
      for(let mealType of this.mealTypes){
        // only update the total if it was pretty much maxed out
        if(this.data.options[mealType].total >= 5) this.data.options[mealType].total = this.data.days;
      }
    },

    createPlan(){

      if(this.isEditMode) return this.updatePlan();
      this.axios.post('/admin/plans', {...this.data}).then(()=>{
        this.$notify({type: 'success', title:'Success', text: 'Το πλάνο δημιουργήθηκε επιτυχώς.'});
        this.redirectTo('plans');

      }).catch(()=>{
        this.$notify({type: 'error', title:'Something went wrong', text: 'Please make sure you have filled all the fields accordingly'});
      })
    },

    updatePlan(){
      if(!this.plan.id) return;
      this.axios.put('/admin/plans/'+this.plan.id, {...this.data}).then((res)=>{
        this.$notify({type: 'success', title:'Success', text: 'Το πλάνο ενημερώθηκε επιτυχώς.'});
        this.$emit('planUpdated', res.data);
        this.hasChangedData = false;
      }).catch(()=>{
        this.$notify({type: 'error', title:'Something went wrong', text: 'Failed to update the product'});

      })
    },
    deletePlan(){
      this.$swal({
        title: 'Θέλετε σίγουρα να διαγράψετε αυτό το πλάνο;',
        text: "Δεν υπάρχει δυνατότητα επαναφοράς!",
        showCancelButton: true,
        icon: 'warning',
        confirmButtonText: 'Ναι, διαγραφή!',
        denyButtonText: 'Όχι, ακύρωση!'
      }).then(res =>{
        if(res.isConfirmed){
          this.axios.delete('/admin/plans/'+this.plan.id).then(()=>{
            this.$notify({type: 'success', title:'Success', text: 'Το πλάνο διαγράφηκε επιτυχώς.'});
            this.redirectTo('plans');
          }).catch(()=>{})
        }
      })
    },
  }
}
</script>