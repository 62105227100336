<template>

  <div id="settings">
    <div class="container-large px-4 px-lg-5">
      <div class="row">
        <h2>Ρυθμίσεις</h2>


        <div class="col-12 p-0 mt-4">
          <b-tabs>

            <b-tab v-for="(tab,index) in tabs" :key="index" :title="tab.title" lazy>
              <component :is="tab.component"></component>
            </b-tab>
          </b-tabs>

        </div>

      </div>
    </div>
  </div>

</template>

<script>
import GlobalNotification from "@/components/Settings/GlobalNotification";
import Cycles from "@/components/Settings/Cycles";
export default{


  components:{GlobalNotification},
  mounted(){

  },
  methods:{
  },
  data(){
    return{
      tabs: [
        {
          title: 'Kύκλοι / παράδοση',
          component: Cycles
        },
        {
          title: 'Ειδοποίηση στην εφαρμογή',
          component: GlobalNotification
        },

      ]
    }
  }
}
</script>