<template>

  <div id="orders">

    <div class="container-large">
      <div class="row align-items-center">
        <div class="col-auto">
          <h2>Παραγγελίες</h2>
        </div>
        <div class="col-auto ml-auto ext-right">
        </div>
      </div>
    </div>

    <div class="container-large my-3">

      <div class="row">

        <div class="col-12 col-md-3">
          <b-form-input v-model="query.search" debounce="500" placeholder="Αναζήτηση παραγγελιών" class="py-2"></b-form-input>
        </div>
        <div class="col-12 col-md-3">
          <date-picker v-model="query.delivery_date" placeholder="Επιλέξτε ημερομηνία αποστολής"></date-picker>
        </div>
        <div class="col-12 col-md-3">
          <b-select v-model="query.is_ioannina">
            <b-select-option :value="null">Προβολή όλων</b-select-option>
            <b-select-option :value="true">Γιάννενα</b-select-option>
            <b-select-option :value="false">Υπόλοιπη Ελλάδα</b-select-option>
          </b-select>
        </div>
        <div class="col-6 col-md-3 text-right">
          <b-dropdown size="sm" variant="primary" class="mr-2" text="Ενέργειες" right>
            <b-dropdown-item :disabled="!query.delivery_date" @click="exportRawMaterialsList">
              Εξαγωγή πρώτων υλών <b-badge variant="warning" v-if="!query.delivery_date">Επιλέξτε ημερομηνία</b-badge>
            </b-dropdown-item>

            <b-dropdown-item :disabled="!query.delivery_date" @click="exportDishesList">
              Εξαγωγή λίστας προϊόντων <b-badge variant="warning" v-if="!query.delivery_date">Επιλέξτε ημερομηνία</b-badge>
            </b-dropdown-item>
            <b-dropdown-item :disabled="!query.delivery_date" @click="exportOrders">
              Εξαγωγή παραγγελιών <b-badge variant="warning" v-if="!query.delivery_date">Επιλέξτε ημερομηνία</b-badge>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
    </div>

    <div v-if="statuses" class="container-large">
      <div class="d-flex align-items-center filters-wrap mt-4">

        <div v-for="item in statuses" :key="item.title" @click="query.status = item.status" class="status-filter mb-4 flex-shrink-0 cursor-pointer mr-3"
        :class="item.status === query.status ? 'active' : ''">
          {{item.title}}
        </div>

      </div>
    </div>


    <div class="container-large  mt-4">
      <vue-good-table
          ref="orders-table"
          mode="remote"
          :pagination-options="{
                enabled: true,
                total: totalOrders,
                setCurrentPage: this.query.page,
                dropdownAllowAll: false,
                perPage: this.query.limit,
                rowsPerPageLabel: 'Ανά σελίδα',
                nextLabel:'Επόμενη',
                prevLabel: 'Προηγούμενη',
                perPageDropdown: [10, 15, 20, 25],
              }"
          :totalRows="totalOrders"
          :sort-options="{ enabled: false }"
          style-class="vgt-table striped condensed"
          theme="polar-bear"
          @on-page-change="params => this.query.page = params.currentPage"
          @on-row-click="params => this.$router.push({name: 'orders-id', params: {orderId: params.row.id}})"
          @on-per-page-change="params => this.query.limit = params.currentPerPage"
          :columns="columns"
          :rows="orders"
          :select-options="{
                  enabled: false,
                  selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
                  selectionInfoClass: 'custom-class',
                  selectionText: 'rows selected',
                  clearSelectionText: 'clear',
                  disableSelectInfo: true, // disable the select info panel on top
                  selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
                }">
        <template slot="table-row" slot-scope="props">

                <div v-if="props.column.field==='status'">
                  <span :style="`font-size:0.9rem;font-weight:bold;color:${status_colors[props.row.status]};`">{{$store.getters.getStatus(props.row.status)}}</span>
                </div>
                  <div v-else-if="props.column.field==='order_setting.type'">
                    <b-badge variant="primary">{{$store.state.types[props.row.order_setting.type]}}</b-badge>
                  </div>
          <div v-else-if="props.column.field==='order_setting.cycle'">
            <b-badge variant="primary">{{props.row.order_setting.cycle}}</b-badge>
          </div>
                <span v-else-if="props.column.field === 'customer'" class="text-sm">


                  {{props.row.firstName}} {{props.row.lastName}} - {{props.row.phone}} <br>
                  {{props.row.email}}
                </span>
                <div v-else>

                  {{props.formattedRow[props.column.field]}}
                </div>
        </template>
        <div slot="emptystate">Δεν βρέθηκαν παραγγελίες</div>
      </vue-good-table>
    </div>


    <div id="print-orders-area" v-show="isPrintingExportedOrders">
      <div v-for="item in exportedOrders" :key="item.id" class="row " style="display: flex;flex-wrap: wrap;">
        <div class="col-6" style="padding-right:40px;max-width:50%;">
          <h5>Στοιχεία Αποστολής</h5>
          <ul class="" style="list-style-type: none;padding-left:0;">
            <li style="margin-left:0;">Order Id: #{{item.id}}</li>
            <li>
              Είδος: {{item.type === 'SUBSCRIPTION' ? 'Συνδρομή': 'A la carte'}}
            </li>
            <li>Ονοματεπώνυμο: {{ item.firstName + ' ' + item.lastName }}</li>
            <li>Διεύθυνση: {{ item.order_shipping_address.address }}, {{ item.order_shipping_address.city }},
              {{ item.order_shipping_address.zipCode }}</li>
            <li>
              Όροφος/Κουδούνι:{{ item.order_shipping_address.door }}
            </li>
            <li>
              Κόστος αποστολής: {{item.shipping_fee}}€
            </li>

          </ul>
        </div>
        <div class="col-6" style="max-width:50%;">
          <h5>Προϊόντα</h5>
          <div v-for="item in item.order_products" :key="item.id">
            {{item.name}} ({{item.size}}) <strong>x {{item.quantity}}</strong>
          </div>
        </div>
        <div class="col-12" style="width:100%;">
          <hr/>
        </div>
      </div>
    </div>
  </div>

</template>
<style lang="scss">
.filters-wrap{
  overflow-y: auto;
  > div {
    font-size:14px;
  }
}
.status-filter{
  border-bottom:1px solid;
  border-color:transparent;
  &.active{
    font-weight:bold;
    border-bottom:1px solid;
    border-color: $primary;
  }
}
.order-item{
  cursor:pointer;
  margin-top:2px;
  &:nth-child(even){
    background-color:$primary_shade;
  }
  border-color:$primary_shade;
  &:hover{
    background-color:$primary_shade;
  }
  > div{
    font-size:13px;
    font-weight:500;
  }
}
</style>
<script>
import {mapState} from "vuex";
import moment from "moment";

export default{

  data(){
    return{
      orders: [],
      totalOrders: 0,
      isPrintingExportedOrders: true,
      exportedOrders: [],
      query:{
        limit: 10,
        page: 1,
        search: '',
        status: null,
        delivery_date: null,
        is_ioannina: null
      },
      loaded: false,
      columns:[
        {label: '#Id', field: 'id'},
        {label: 'Ημ. Αποστολής', field: 'delivery_date', formatFn: val => moment(val).format('DD MMM YYYY')},
        {label: 'Κατάσταση', field: 'status'},
        {label: 'Είδος', field: 'order_setting.type'},
        {label: 'Σύνολο παραγγελίας', field: 'total', formatFn: (val) => (val ? val.toFixed(2) : '0.00') + '€'},
        {label: 'Κύκλος παραγγελίας', field: 'order_setting.cycle'},
        {label: 'Στοιχεία Πελάτη', field: 'customer'},
      ]
    }
  },
  computed:{
    ...mapState({
      statuses: state => state.statuses,
      status_colors: state => state.status_colors
    })
  },

  watch:{

    'query.page'(val){
      if(!this.loaded) return;
      this.fixUrlQuery('page', val);
      this.getOrders();
    },

    'query.status'(val){
      if(!this.loaded) return;
      this.query.page = 1;
      this.fixUrlQuery('status', val);
      this.getOrders()
    },

    'query.delivery_date'(val){
      if(!this.loaded) return;

      this.query.page = 1;
      let clearVal;
      if(val){
        clearVal = new Date(val.setHours(5,0,0)).toISOString().substring(0,10);
      }else{
        clearVal = null;
      }
      this.fixUrlQuery('delivery_date', clearVal);
      this.getOrders()
    },
    'query.is_ioannina'(val){
      if(!this.loaded) return;
      this.query.page = 1;

      this.fixUrlQuery('is_ioannina', val !== null ? val.toString() : null);
      this.getOrders()
    },


    'query.search'(val){
      if(!this.loaded) return;
      this.query.page = 1;
      this.fixUrlQuery('search', val);
      this.getOrders()
    }

  },

  mounted(){


    if(this.$route.query.page) this.query.page = this.$route.query.page
    if(this.$route.query.search) this.query.search = this.$route.query.search;
    if(this.$route.query.status) this.query.status = this.$route.query.status;
    if(this.$route.query.delivery_date) this.query.delivery_date = new Date(this.$route.query.delivery_date);


    this.getOrders()
  },

  methods:{

    fixUrlQuery(key, value){
      let query = {...this.$route.query}

      if(!value) delete query[key]
      else query[key] = value;
      this.$router.push({query})
    },
    getOrders(){

      let query = {...this.query};

      query.offset = (query.page-1) * query.limit

      if(query.delivery_date) query.delivery_date = new Date(query.delivery_date).toISOString().substring(0,10);

      this.axios.get('/admin/orders', {params: query}).then(res=>{
        this.orders = res.data.rows;
        this.totalOrders = res.data.count;
        this.loaded = true;
      }).catch(()=>{
        this.$notify({type: 'error', title:'Something went wrong', text: 'Please try again later!'});
      })
    },

    exportRawMaterialsList(){
      let date = new Date(this.query.delivery_date).toISOString().substring(0,10);
      this.axios.get('/admin/orders/bulk/raw-materials', {params:{delivery_date: date, is_ioannina: this.query.is_ioannina}}).then(res=>{

        const hiddenElement = document.createElement('a');
        hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(res.data);
        hiddenElement.target = '_blank';
        hiddenElement.download = 'dreamdiet_quantities_' + date + '.csv';
        hiddenElement.click();

      }).catch(()=>{
        this.$notify({type: 'error', title:'Something went wrong', text: 'Please try again later!'});
      })
    },

    exportDishesList(){
      let date = new Date(this.query.delivery_date).toISOString().substring(0,10);
      this.axios.get('/admin/orders/bulk/dishes', {params:{delivery_date: date, is_ioannina: this.query.is_ioannina}}).then(res=>{

        const hiddenElement = document.createElement('a');
        hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(res.data);
        hiddenElement.target = '_blank';
        hiddenElement.download = 'dreamdiet_dishes_' + date + '.csv';
        hiddenElement.click();

      }).catch(()=>{
        this.$notify({type: 'error', title:'Something went wrong', text: 'Please try again later!'});
      })
    },

    exportOrders() {
      let date = new Date(this.query.delivery_date).toISOString().substring(0, 10);
      this.axios.get('/admin/orders/bulk/orders', {
        params: {
          delivery_date: date,
          is_ioannina: this.query.is_ioannina
        }
      }).then(res => {

        this.exportedOrders = res.data;
        this.printOrders();

      }).catch(() => {
        this.$notify({type: 'error', title: 'Something went wrong', text: 'Please try again later!'});
      })
    },

    printOrders(){
      this.isPrintingExportedOrders = true;
      this.$nextTick(()=>{
        const html = document.getElementById('print-orders-area').innerHTML;
        this.isPrintingExportedOrders = false;
        var mywindow = window.open('', 'PRINT', 'toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=900,height=900');

        mywindow.document.write('<html><head><title>' + document.title  + '</title>');
        mywindow.document.write('<style>*{font-family:"Arial",sans-serif;}.row{margin:-20px 0;}' +
            '@page {margin: 3.5mm;}' +
            '@media print {body {margin: 5px;}html, body { height: 99%;}}'+
            '</style>')
        mywindow.document.write('</head><body >');
        mywindow.document.write(html);
        mywindow.document.write('</body></html>');

        mywindow.document.close(); // necessary for IE >= 10
        mywindow.focus(); // necessary for IE >= 10*/

        mywindow.print();


        setTimeout(function () {
          mywindow.close();
          }, 100);

        return true;
      })

    }
  }

}
</script>