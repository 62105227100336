<template>

  <div id="products">
    <div class="container">
      <div class="row">
        <h2>Στατιστικά</h2>
      </div>
    </div>
  </div>

</template>

<script>
export default{

}
</script>